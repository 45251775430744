import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import classNames from 'classnames';
import { useParams, useSearchParams } from 'react-router-dom';
import { KeyedMutator } from 'swr/_internal';
import { useMediaQuery } from '@mui/material';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import DownloadTemplateModal, { SelectedTemplate } from '../../../../DownloadTemplateModal/DownloadTemplateModal.tsx';
import styles from './QualityGateDeliverable.module.scss';
import DownloadSVG from '../../../../../public/media/simple-download.svg';
import DeliverableOptionsDropdown from '../DeliverableOptionsDropdown/DeliverableOptionsDropdown.tsx';
import { Deliverable, DeliverableLink } from '../../../NewProject/NewProjectDeliverables/types.ts';
import AttachSVG from '../../../../../public/media/attach.svg';
import AngleDownSVG from '../../../../../public/media/angle-down.svg';

import { DEFAULT_DATE_FORMAT, MOBILE_MEDIA_QUERY } from '../../../../../constants.ts';
import { userAtom } from '../../../../../store/auth.ts';
import CheckboxItem from '../../../../UIKit/CheckboxItem/CheckboxItem.tsx';
import DeliverableLinks from '../DeliverableLinks/DeliverableLinks.tsx';
import WarningSVG from '../../../../../public/media/warning.svg';
import CheckedSVG from '../../../../../public/media/checked.svg';
import { ProjectPermissions } from '../../../Login/user.props.ts';
import DeleteDeliverableModal from '../DeleteDeliverableModal/DeleteDeliverableModal.tsx';
import apiClient from '../../../../../apiClient.ts';
import { notify } from '../../../../../store/notifications.ts';
import { GateResource } from '../../types.ts';
import Button, { ButtonIconPosition, ButtonSize, ButtonVariants } from '../../../../UIKit/Button/Button.tsx';
import DeleteSVG from '../../../../../public/media/delete-icon.svg';

type QualityGateDeliverableProps = {
  deliverable: Deliverable,
  permissions: string[],
  isReadOnly: boolean,
  mutate: KeyedMutator<{ data: GateResource, permissions: string[] }>
};

const RiskStatusLabel = ({ t } : { t: TFunction<'translation', undefined> }) => (
  <div className={styles.deliverable__risk}>
    <svg>
      <use
        xlinkHref={`${WarningSVG}#warningSVG`}
        href={`${WarningSVG}#warningSVG`}
      />
    </svg>
    {t('Task at risk')}
  </div>
);

const StatusCheckbox = ({
  updateDeliverable,
  userHasPermission,
  isDone,
  t,
}: {
  updateDeliverable: (values: Record<string, any>) => void;
  userHasPermission: boolean;
  isDone: boolean;
  t: TFunction<'translation', undefined>;
}) => (userHasPermission ? (
  <div className={styles.deliverable__mark}>
    <CheckboxItem
      iconSize={18}
      fontSize={12}
      label={t('Mark as done')}
      value={isDone}
      onChange={(e) => updateDeliverable({ done: +e.target.checked })}
    />
  </div>
) : isDone ? (
  <div className={styles.deliverable__done}>
    <svg>
      <use
        xlinkHref={`${CheckedSVG}#checkedSVG`}
        href={`${CheckedSVG}#checkedSVG`}
      />
    </svg>
    {t('Done')}
  </div>
) : null);

const QualityGateDeliverable = ({
  deliverable, permissions, isReadOnly, mutate,
}: QualityGateDeliverableProps) => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const userData = useAtomValue(userAtom);

  const [isOpen, setIsOpen] = useState(false);
  const [allLinks, setAllLinks] = useState<DeliverableLink[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const isMobileDevice = useMediaQuery(MOBILE_MEDIA_QUERY);

  useEffect(() => {
    setAllLinks(deliverable.links ?? []);
  }, []);

  useEffect(() => {
    const activeDeliverable = searchParams.get('active');

    if (!activeDeliverable) return;

    if (permissions.includes(ProjectPermissions.UPDATE) && !isReadOnly) {
      setIsOpen(+activeDeliverable === deliverable.id);
    }
    searchParams.delete('active');
    setSearchParams(searchParams, { replace: true });
  }, [searchParams.get('active')]);

  const updateDeliverable = async (values: Record<string, any>) => {
    try {
      const { statusCode } = await apiClient.put(`projects/${projectId}/deliverables/${deliverable.id}`, {
        body: JSON.stringify(values),
      });

      if (statusCode !== 200) throw new Error();
      mutate();
    } catch (e) {
      console.error(e);
      notify();
    } finally {
      setIsDeleteModalOpen(false);
    }
  };

  const [selectedTemplates, setSelectedTemplates] = useState<SelectedTemplate[] | null>(null);
  const isDownloadButtonVisible = deliverable.templates.length > 0 && deliverable.templates.some(template => template.link === null);
  const isDeleteButtonVisible = permissions.includes(ProjectPermissions.DELETE) && !isReadOnly;
  const isExpandButtonVisible = permissions.includes(ProjectPermissions.UPDATE) && !isReadOnly;

  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.deliverable, {
        [styles.isOpen]: isOpen,
      })}
      >
        <div>
          {permissions.includes(ProjectPermissions.DELETE) && !isReadOnly && isMobileDevice && (
          <DeliverableOptionsDropdown
            permissions={permissions}
            isReadOnly={isReadOnly}
            onDeleteClick={() => setIsDeleteModalOpen(true)}
            onDownload={() => setSelectedTemplates(deliverable.templates)}
            showDownloadButton={isDownloadButtonVisible}
          />
          )}
          <div className={styles.deliverable__inner}>
            <div className={styles.deliverable__info}>
              <span>{dayjs(deliverable.due_date).format(userData?.user.dateFormat ?? DEFAULT_DATE_FORMAT)}</span>
              {allLinks?.length ? (
                <>
                  <span className={styles.divider} />
                  <svg>
                    <use
                      xlinkHref={`${AttachSVG}#attachSVG`}
                      href={`${AttachSVG}#attachSVG`}
                    />
                  </svg>
                  <span>{`${allLinks.length} ${allLinks.length === 1 ? t('document') : t('documents')}`}</span>
                </>
              ) : null}
            </div>
            <h6 className={styles.deliverable__title}>{deliverable.caption}</h6>
            {isMobileDevice && (
              <>
                {!!deliverable?.task?.task_at_risk && (
                <RiskStatusLabel t={t} />
                )}
                <StatusCheckbox
                  updateDeliverable={updateDeliverable}
                  isDone={Boolean(deliverable.done)}
                  userHasPermission={permissions.includes(ProjectPermissions.UPDATE)}
                  t={t}
                />
              </>
            )}
          </div>
        </div>
        <div>
          {!isMobileDevice && (
            <>
              {!!deliverable?.task?.task_at_risk && (
                <RiskStatusLabel t={t} />
              )}
              <StatusCheckbox
                updateDeliverable={updateDeliverable}
                isDone={Boolean(deliverable.done)}
                userHasPermission={permissions.includes(ProjectPermissions.UPDATE)}
                t={t}
              />
            </>
          )}
          <Button
            variant={ButtonVariants.TRANSPARENT}
            size={ButtonSize.SMALL}
            icon={(
              <svg>
                <use
                  xlinkHref={`${DownloadSVG}#simpleDownloadSVG`}
                  href={`${DownloadSVG}#simpleDownloadSVG`}
                />
              </svg>
              )}
            iconPosition={ButtonIconPosition.CENTER}
            iconSize={17}
            onClick={() => setSelectedTemplates(deliverable.templates)}
            className={classNames(styles.deliverable__button, {
              [styles.deliverable__button_hidden]: !isDownloadButtonVisible,
            })}
          />
          <Button
            variant={ButtonVariants.TRANSPARENT}
            size={ButtonSize.SMALL}
            icon={(
              <svg>
                <use
                  xlinkHref={`${DeleteSVG}#deleteSVG`}
                  href={`${DeleteSVG}#deleteSVG`}
                />
              </svg>
            )}
            iconPosition={ButtonIconPosition.CENTER}
            iconSize={17}
            onClick={() => setIsDeleteModalOpen(true)}
            className={classNames(styles.deliverable__button, {
              [styles.deliverable__button_hidden]: !isDeleteButtonVisible,
            })}
          />
          {permissions.includes(ProjectPermissions.UPDATE) && !isReadOnly ? (
            <Button
              variant={ButtonVariants.TRANSPARENT}
              size={ButtonSize.SMALL}
              iconPosition={ButtonIconPosition.CENTER}
              icon={(
                <svg>
                  <use
                    xlinkHref={`${AngleDownSVG}#angleDownSVG`}
                    href={`${AngleDownSVG}#angleDownSVG`}
                  />
                </svg>
              )}
              iconSize={17}
              className={classNames(
                styles.deliverable__button,
                styles.deliverable__toggleButton,
                { [styles.deliverable__button_hidden]: !isExpandButtonVisible },
              )}
              onClick={() => setIsOpen(!isOpen)}
            />
          ) : null}
        </div>
      </div>
      {isOpen && (
        <DeliverableLinks
          links={allLinks}
          permissions={permissions}
          isReadOnly={isReadOnly}
          deliverable={{ id: deliverable.id, caption: deliverable.caption }}
          updateLinks={setAllLinks}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteDeliverableModal
          closeModal={() => setIsDeleteModalOpen(false)}
          deleteDeliverable={() => updateDeliverable({ quality_gate: null })}
        />
      )}
      {selectedTemplates && (
        <DownloadTemplateModal
          selectedTemplates={selectedTemplates}
          setSelectedTemplates={setSelectedTemplates}
        />
      )}
    </div>
  );
};

export default QualityGateDeliverable;
