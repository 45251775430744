import { redirect } from 'react-router-dom';
import { getAuthorizeToken } from '../utils';

export async function authGuard() {
  const authorizeToken = getAuthorizeToken();

  if (!authorizeToken) {
    return redirect('/login');
  }
  return null;
}
