import { PrimitiveAtom } from 'jotai/vanilla/atom';
import { ProjectResource } from '../Projects/types.ts';

export enum Redirect {
  MANAGE = '/m',
  DASHBOARD = '/d',
}

export type ProgressAtomsType = {
  parameters: PrimitiveAtom<number>,
  questions: PrimitiveAtom<number>,
  deliverables: PrimitiveAtom<number>,
};

export type NewProjectContext = {
  isAiLoadingScreenVisible: boolean,
  setIsAiLoadingScreenVisible: (isAiLoadingScreenVisible: boolean) => void,
  progressAtoms: ProgressAtomsType,
  projectAtom: PrimitiveAtom<ProjectResource>,
  setInteractionBlocker: React.Dispatch<React.SetStateAction<{
    isVisible: boolean;
    callback: null | (() => void);
  }>>,
  pollingSettings: {
    callback: (response?: {
      data: ProjectResource;
    }) => Promise<void>;
    isTriggered: boolean;
  }
  setPollingSettings: React.Dispatch<React.SetStateAction<{
    callback: ((response?: {
      data: ProjectResource;
    }) => Promise<void>) | null;
    isTriggered: boolean;
  }>>,
  getEditAccess: () => Promise<void>,
};
