import { useAtomValue } from 'jotai';
import classNames from 'classnames';
import { NotificationPlacement, notificationsAtom } from '../../store/notifications.ts';
import Portal from '../Portal.tsx';
import Notification from '../UIKit/Notification/Notification.tsx';
import styles from './Notifications.module.scss';

const Notifications = () => {
  const { notifications } = useAtomValue(notificationsAtom);

  const isPopupNotification = notifications.some(({ type }) => type === 'popup');
  const notificationForDisplay = isPopupNotification ? notifications.filter(({ type }) => type === 'popup') : notifications;

  return (
    <Portal
      className={classNames(styles.notificationsPortalRoot, {
        [styles.notificationsPortalRoot_bottomPlacement]: notifications?.[0]?.placement === NotificationPlacement.BOTTOM,
        [styles.notificationsPortalRoot_wide]: isPopupNotification,
      })}
    >
      {notificationForDisplay.map((notification) => (
        <Notification
          {...notification}
          key={notification.id}
        />
      ))}
    </Portal>
  );
};

export default Notifications;
