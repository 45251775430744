import {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Redirect } from '../components/pages/NewProject/types';
import { Permissions } from '../components/pages/Login/user.props';
import { eventEmitter } from '../eventEmitter.ts';
import { NotificationOptionView, NotificationPlacement, notify } from '../store/notifications';
import apiClient from '../apiClient.ts';
import useTimer from '../useTimer';
import { usePermissions } from '../usePermission';
import { ProjectEditAccess, UserThatCurrentlyEditingInfo } from '../components/pages/Projects/types.ts';
import { editAccessGererator } from '../store/project.ts';

const ARE_YOU_STILL_HERE_MODAL_DEFAULT_VALUE = { isVisible: false };

export const endEditingSession = async (projectId: number) => {
  try {
    const { statusCode } = await apiClient.get<{ data: UserThatCurrentlyEditingInfo }>(`projects/${projectId}/edit-mode/clear`);
    if (statusCode === 200) {
      return true;
    }
    throw new Error();
  } catch (e) {
    console.error(e);
    return false;
  }
};

const useEditAccessHandler = (
  clientId: string | undefined,
  projectId: string | undefined,
  storedProjectEditAccess: ProjectEditAccess,
  setStoredProjectEditAccess: React.Dispatch<React.SetStateAction<ProjectEditAccess>>,
  // Some pages can be accessed without edit permissions,
  // so we need to check if requesting edit access is necessary.
  userHasEditPermission: boolean = true,
) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { hasPermission } = usePermissions();
  const prefix = hasPermission(Permissions.ADMIN) ? Redirect.MANAGE : Redirect.DASHBOARD;

  const [areYouStillHereModal, setAreYouStillHereModal] = useState(ARE_YOU_STILL_HERE_MODAL_DEFAULT_VALUE);

  const longDuration = 285000; // 4 min 45 sec
  const accessCheckInterval = 5000;

  const getEditAccess = async () => {
    try {
      if (!projectId || window.location.pathname.includes('/login')) return;

      const { statusCode, response } = await apiClient
        .get<{ data: UserThatCurrentlyEditingInfo }>(`projects/${projectId}/edit-mode`);

      if (statusCode === 200) {
        setStoredProjectEditAccess(editAccessGererator({
          type: 'SET_PROJECT_EDITING',
          payload: { projectId: Number(projectId), requestedAt: response?.data?.requested_at },
        }));
      } else if (statusCode === 409) {
        setStoredProjectEditAccess(editAccessGererator({
          type: 'SET_EDITING_UNAVAILABLE',
          payload: {
            user: response.data,
            projectId: Number(projectId),
          },
        }));
      } else {
        throw new Error();
      }
    } catch (e) {
      notify({ text: e.message ? { body: e.message } : {} });
      console.error(e);
    }
  };

  const checkEditAccess = async () => {
    try {
      if (!projectId) return;

      const { statusCode, response } = await apiClient
        .get<{ data: UserThatCurrentlyEditingInfo | null }>(`projects/${projectId}/edit-mode/check`);

      if (statusCode === 200) {
        setStoredProjectEditAccess(editAccessGererator(
          { type: 'SET_EDITING_AVAILABLE', payload: { projectId: Number(projectId) } },
        ));
      } else if (statusCode === 409 && response.data) {
        setStoredProjectEditAccess(editAccessGererator({
          type: 'SET_EDITING_UNAVAILABLE',
          payload: {
            user: response.data,
            projectId: Number(projectId),
          },
        }));
      } else {
        throw new Error();
      }
    } catch (e) {
      notify({ text: e.message ? { body: e.message } : {} });
      console.error(e);
    }
  };

  const getOutHandler = async ({
    shouldNavigateToProjects = false,
    isAutomaticRedirect = false,
  }: {
    shouldNavigateToProjects?: boolean;
    isAutomaticRedirect?: boolean;
  }) => {
    try {
      // console.log('StoredProjectEditAccess in getPutHandler', storedProjectEditAccess);
      if (!projectId) return;

      const { statusCode } = await apiClient
        .get<{ data: UserThatCurrentlyEditingInfo }>(`projects/${projectId}/edit-mode/clear`);

      if (statusCode === 200) {
        setStoredProjectEditAccess(editAccessGererator({ type: 'GET_OUT' }));
        setAreYouStillHereModal(ARE_YOU_STILL_HERE_MODAL_DEFAULT_VALUE);

        if (shouldNavigateToProjects && isAutomaticRedirect) {
          navigate(`${prefix}/client/${clientId}/projects`);
          notify({
            text: {
              title: t('Exit edit mode'),
              body: t('You have been redirected to this page because your project editing session has expired'),
            },
            duration: undefined,
            placement: NotificationPlacement.BOTTOM,
            type: NotificationOptionView.POPUP,
          });
        } else if (shouldNavigateToProjects) {
          navigate(`${prefix}/client/${clientId}/projects`);
        }
      } else {
        throw new Error();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleShortTimePassed = () => {
    getOutHandler({ shouldNavigateToProjects: true });
  };

  const handleLongTimePassed = () => {
    setAreYouStillHereModal({ isVisible: true });
  };

  const handleCheckEditAccess = () => {
    if (!storedProjectEditAccess.isEditingAvailableShowOnUI) {
      checkEditAccess();
    }
  };

  const {
    valueOfTimer,
    numberOfTimer,
    startLongTimer,
    startCheckAccessTimer,
    resetTimers,
  } = useTimer(
    handleShortTimePassed,
    handleLongTimePassed,
    handleCheckEditAccess,
    longDuration,
  );

  const confirmPresenceHandler = () => {
    setAreYouStillHereModal(ARE_YOU_STILL_HERE_MODAL_DEFAULT_VALUE);
    getEditAccess();
    resetTimers();
  };

  const handleBeforeUnload = (event: { returnValue: string }) => {
    if (!storedProjectEditAccess?.isEditingAvailableShowOnUI) return;

    eventEmitter.emit('tabClosed');
    const warningText = t('Are you sure you want to leave?');
    event.returnValue = warningText;
    return warningText;
  };

  const firstRender = useRef(true);

  useLayoutEffect(() => {
    if (projectId) {
      const userWantAndCanEditProject = !storedProjectEditAccess?.isViewMode && userHasEditPermission && storedProjectEditAccess.projectId;
      const needToUpdateStoredData = (typeof storedProjectEditAccess.projectId === 'number')
        && (projectId !== String(storedProjectEditAccess.projectId));

      const isUserStillLoggedIn = !pathname.includes('/login');
      if ((userWantAndCanEditProject || needToUpdateStoredData) && isUserStillLoggedIn) {
        getEditAccess();
        firstRender.current = false;
      }
    } else {
      setStoredProjectEditAccess(editAccessGererator({ type: 'GET_OUT' }));
    }
  }, [pathname, storedProjectEditAccess.projectId]);

  useEffect(() => {
    if (!firstRender.current && areYouStillHereModal?.isVisible && numberOfTimer === 0) {
      getOutHandler({ shouldNavigateToProjects: true, isAutomaticRedirect: true });
    }
  }, [numberOfTimer]);

  useEffect(() => {
    if (!storedProjectEditAccess.isEditingAvailableShowOnUI && projectId && !storedProjectEditAccess?.isViewMode) {
      startCheckAccessTimer(accessCheckInterval);
    } else {
      resetTimers();
    }
  }, [storedProjectEditAccess]);

  useEffect(() => {
    if (
      storedProjectEditAccess.isEditingAvailableShowOnUI
      && projectId
      && !storedProjectEditAccess?.isViewMode
      && storedProjectEditAccess.requestedAt
    ) {
      const requestedAtDate = new Date(storedProjectEditAccess.requestedAt);
      const now = new Date();
      const timeDifference = now.getTime() - requestedAtDate.getTime();
      const remainingTime = Math.max(longDuration - timeDifference, 0);

      startLongTimer(remainingTime);
    }
  }, [storedProjectEditAccess, projectId]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    eventEmitter.once('tabClosed', () => {
      getOutHandler({ shouldNavigateToProjects: false });
    });

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [storedProjectEditAccess?.isEditingAvailableShowOnUI]);

  useEffect(() => () => {
    // function in setTimeout checks whether the user has finished editing the project
    // or has navigated to the editing page of a project component (e.g., the manage deliverables or a specific quality gate page).
    setTimeout(() => {
      const currectLocation = window.location.pathname;
      const isUserLoggedOut = currectLocation.includes('/login');
      const isUserStillOnProjectPage = currectLocation.includes('project/');
      const isUserEditSameProject = currectLocation.includes(String(storedProjectEditAccess?.projectId));
      const isUserInViewMode = storedProjectEditAccess?.isViewMode;
      if (!isUserLoggedOut && !isUserStillOnProjectPage && !isUserEditSameProject) {
        !isUserInViewMode ? getOutHandler({}) : setStoredProjectEditAccess(editAccessGererator({ type: 'GET_OUT' }));
      }
    }, 600);
  }, [pathname]);

  return {
    areYouStillHereModal,
    confirmPresenceHandler,
    valueOfTimer,
    numberOfTimer,
    getOutHandler,
    getEditAccess,
    accessCheckInterval,
  };
};

export default useEditAccessHandler;
