import classNames from 'classnames';
import { TFunction } from 'i18next';
import styles from './NewProject.module.scss';
import AiSVG from '../../../public/media/ai.svg';

const AILabel = ({
  children, isLabelVisible, t, className,
}: {
  children: React.ReactNode,
  isLabelVisible: boolean,
  t: TFunction<'translation', undefined>
  className?: string,
}) => (
  <div className={classNames(styles.iaLabelWrapper, className, {
    [styles.iaLabelWrapper_active]: isLabelVisible,
  })}
  >
    {isLabelVisible && (
    <div
      className={styles.aiLabel}
      data-role='ai-label'
    >
      <svg>
        <use
          xlinkHref={`${AiSVG}#aiSVG`}
          href={`${AiSVG}#aiSVG`}
        />
      </svg>
      <p>
        {t('AI Generated')}
      </p>
    </div>
    )}
    {children}
  </div>
);

export default AILabel;
