import { useAtomValue, useAtom, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import {
  createBrowserRouter, Outlet, redirect, RouterProvider,
} from 'react-router-dom';
import { useEffect } from 'react';
import useSWR from 'swr';
import { clientCiLoader } from './actions/clientCiLoader.ts';
import { newPasswordLoader } from './actions/new-password.loader.ts';
import { newProjectDeliverablesLoader } from './actions/new-project-deliverables.loader.ts';
import { projectTrackingLoader } from './actions/project-tracking.loader.ts';
import ClientCIConfirm from './components/ClientCIConfirm/ClientCIConfirm.tsx';
import Archive from './components/pages/Archive/Archive';
import Login from './components/pages/Login/Login';
import ManageDeliverables from './components/pages/NewProject/ManageDeliverables/ManageDeliverables.tsx';
import NewProject from './components/pages/NewProject/NewProject';
import NewProjectDeliverables from './components/pages/NewProject/NewProjectDeliverables/NewProjectDeliverables';
import NewProjectParameters from './components/pages/NewProject/NewProjectParameters/NewProjectParameters';
import NewProjectSummary from './components/pages/NewProject/NewProjectSummary/NewProjectSummary';
import SummaryDashboard from './components/pages/NewProject/NewProjectSummary/SummaryDashboard/SummaryDashboard';
import SummarySchedule from './components/pages/NewProject/NewProjectSummary/SummarySchedule/SummarySchedule';
import NewPassword from './components/pages/PasswordConfig/NewPassword';
import ForgotPassword from './components/pages/PasswordConfig/ForgotPassword';
import UIKitPage from './components/pages/UIKitPage/UIKit';
import ProjectTracking from './components/ProjectTracking/ProjectTracking.tsx';
import Portfolio from './components/Portfolio/Portfolio.tsx';
import { DEV_MODE } from './constants';
import Clients from './components/pages/Clients/Clients';
import Dashboard from './components/pages/Dashboard/Dashboard';
import Projects from './components/pages/Projects/Projects';
import Users from './components/pages/Users/Users';
import Customers from './components/pages/Customers/Customers';
import Home from './components/pages/Home/Home';
import AccountSettings from './components/pages/AccountSettings/AccountSettings';
import Templates from './components/pages/Templates/Templates';
import NewProjectQuestions from './components/pages/NewProject/NewProjectQuestions/NewProjectQuestions';
import NotFound from './components/pages/NotFound/NotFound';
import NoAccess from './components/pages/NoAccess/NoAccess.tsx';
import QualityGate from './components/pages/QualityGate/QualityGate.tsx';
import QualityGateDetails from './components/pages/QualityGate/QualityGateDetails/QualityGateDetails.tsx';
import Voting from './components/pages/QualityGate/Voting/Voting.tsx';
import ProjectManagment from './components/pages/ProjectTeamManagment/ProjectTeamManagment.tsx';
import QualityGates from './components/pages/NewProject/NewProjectSummary/QualityGates/QualityGates.tsx';
import Constraints from './components/pages/QualityGate/Constraints/Constraints.tsx';
import QualityGateHistory from './components/pages/QualityGate/QualityGateHistory/QualityGateHistory.tsx';
import UserManual from './components/pages/UserManual/UserManual.tsx';
import SelfVoting from './components/pages/SelfVoting/SelfVoting.tsx';
import ErrorPage from './components/pages/ErrorPage/ErrorPage.tsx';
import ToolIntegration from './components/pages/ToolIntegration/ToolIntegration.tsx';
import ReleaseNotes from './components/pages/ReleaseNotes/ReleaseNotes.tsx';
import ProjectRisks from './components/pages/NewProject/NewProjectSummary/ProjectRisks/ProjectRisks.tsx';

import {
  checkPermissionsLoader,
  checkViewLoader,
  clientLoader,
  customersLoader,
  dashboardIndexLoader,
  dashboardLoader,
  mainLoader,
  newProjectLoader,
  newProjectQuestionsLoader,
  projectSummaryLoader,
  authGuard,
  risksPageLoader,
} from './actions';
import Notifications from './components/Notifications/Notifications.tsx';
import apiClient from './apiClient';
import { clientPreviewIdAtom } from './store/clientCI.ts';
import { languagesAtom, Locales } from './store/lang';
import { Permissions } from './components/pages/Login/user.props.ts';
import { globalPermissionsAtom, latestReleaseAtom, userAtom } from './store/auth.ts';
import { ILatestRelease, UserResource } from './components/pages/Users/UsersList/types.ts';

import './i18n.config';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Outlet />,
    children: [
      { index: true, loader: mainLoader, element: <Home /> },
      { path: '/login', element: <Login /> },
      { path: '/login/:provider', element: <Login /> },
      { path: '/new-password', loader: newPasswordLoader, element: <NewPassword /> },
      { path: '/forgot-password', element: <ForgotPassword /> },
      {
        path: '/ui',
        loader: ({ params }) => clientCiLoader(params),
        element: DEV_MODE === 'true' ? <UIKitPage /> : <NotFound />,
      },
      { path: '*', element: <NotFound withLogo /> },
    ],
  },
  {
    path: '/d',
    loader: ({ params }) => dashboardLoader(params),
    children: [
      { index: true, loader: dashboardIndexLoader },
      {
        element: <Dashboard />,
        errorElement: <ErrorPage />,
        children: [
          { path: 'releases', element: <ReleaseNotes /> },
          { path: 'account-settings', element: <AccountSettings /> },
          {
            path: 'client/:clientId/projects',
            loader: ({ params }) => checkViewLoader(params, Permissions.PROJECT_VIEW, `client/${params.clientId}/projects`),
            element: <Projects />,
          },
          {
            path: 'client/:clientId/projects/archive',
            loader: ({ params }) => checkViewLoader(params, Permissions.PROJECT_ARCHIVE, `client/${params.clientId}/projects/archive`),
            element: <Projects isArchiveView />,
          },
          {
            path: 'client/:clientId/projects/:projectId/members',
            loader: ({ params }) => checkPermissionsLoader(params, Permissions.PROJECT_MANAGE),
            element: <ProjectManagment />,
          },
          {
            path: 'client/:clientId/new-project',
            element: <NewProject />,
            children: [
              {
                index: true,
                loader: ({ params }) => customersLoader(params, Permissions.PROJECT_CREATE),
                element: <NewProjectParameters />,
              },
            ],
          },
          {
            path: 'client/:clientId/projects/portfolio',
            loader: ({ params }) => checkPermissionsLoader(params, Permissions.PORTFOLIO),
            element: <Portfolio />,
          },

          {
            path: 'client/:clientId/project/:projectId',
            loader: newProjectLoader,
            id: 'project',
            element: <NewProject />,
            children: [
              { index: true, loader: ({ params }) => customersLoader(params), element: <NewProjectParameters /> },
              { path: 'questions', loader: newProjectQuestionsLoader, element: <NewProjectQuestions /> },
              { path: 'deliverables', loader: newProjectDeliverablesLoader, element: <NewProjectDeliverables /> },
              {
                path: 'summary',
                element: <NewProjectSummary />,
                children: [
                  {
                    index: true,
                    loader: projectSummaryLoader,
                    element: <SummaryDashboard />,
                  },
                  {
                    path: 'schedule',
                    element: <SummarySchedule />,
                  },
                  { path: 'quality-gates', element: <QualityGates /> },
                ],
              },
            ],
          },
          {
            path: 'client/:clientId/project/:projectId/gate/:gateId',
            loader: newProjectLoader,
            element: <QualityGate />,
            children: [
              { index: true, element: <QualityGateDetails /> },
              { path: 'voting', element: <Voting /> },
              { path: 'constraints', element: <Constraints /> },
              { path: 'history', element: <QualityGateHistory /> },
            ],
          },
          {
            path: 'client/:clientId/project/:projectId/manage-deliverables',
            loader: ({ params }) => customersLoader(params, Permissions.DELIVERABLE_UPDATE),
            element: <ManageDeliverables />,
          },
          {
            path: 'client/:clientId/project/:projectId/manage-risks',
            loader: risksPageLoader,
            element: <ProjectRisks />,
          },
          {
            path: 'client/:clientId/project/:projectId/tracking',
            loader: projectTrackingLoader,
            element: <ProjectTracking />,
          },
          { path: '*', element: <NotFound /> },
        ],
      },
    ],
  },
  {
    path: '/m',
    loader: ({ params }) => {
      clientCiLoader(params);
      return checkPermissionsLoader(params, Permissions.ADMIN);
    },
    children: [
      {
        index: true,
        loader() {
          return redirect('client');
        },
      },
      {
        path: 'client',
        loader: clientLoader,
        element: <Clients />,
        children: [
          { path: ':id/users', element: <Users />, index: true },
          { path: ':id/customers', loader: ({ params }) => customersLoader(params), element: <Customers /> },
          {
            path: ':id/templates',
            loader: ({ params }) => checkPermissionsLoader(params, Permissions.TEMPLATE_VIEW),
            element: <Templates />,
          },
          { path: ':id/projects', element: <Projects adminView /> },
          {
            path: ':id/tool_integration',
            loader: ({ params }) => checkPermissionsLoader(params, Permissions.INTEGRATION_VIEW),
            element: <ToolIntegration />,
          },
          { path: '*', element: <NotFound /> },
        ],
      },
      {
        path: 'client',
        loader: clientLoader,
        element: <Archive />,
        children: [
          {
            path: ':id/projects/archive',
            element: (
              <Projects
                isArchiveView
                adminView
              />
            ),
          },
          { path: '*', element: <NotFound /> },
        ],
      },
      { path: '*', element: <NotFound withLogo /> },
    ],
  },
  { path: '/not-found', element: <NotFound withLogo /> },
  { path: '/access-denied', element: <NoAccess /> },
  { path: '/user-manual', loader: authGuard, element: <UserManual /> },
  { path: '/voting/:id', element: <SelfVoting /> },
]);

const App = () => {
  const { i18n } = useTranslation();
  const setLanguages = useSetAtom(languagesAtom);
  useSWR(['translations/locales', i18n.language], ([url]) => apiClient.withoutAuth().get<{ data: Locales }>(url).then(data => {
    const { response: { data: langs } } = data;
    setLanguages(langs);
  }), {
    revalidateIfStale: false, revalidateOnFocus: false, revalidateOnReconnect: false,
  });
  const [user, setUser] = useAtom(userAtom);
  const setGlobalPermissions = useSetAtom(globalPermissionsAtom);
  const setLatestRelease = useSetAtom(latestReleaseAtom);

  const clientPreviewId = useAtomValue(clientPreviewIdAtom);

  useEffect(() => {
    // force refresh user data for current user
    if (user) {
      apiClient.get<{ data: UserResource, permissions: string[], release_notification: ILatestRelease }>('user')
        .then(({ response }) => {
          setUser({ ...user!, user: response.data });
          setGlobalPermissions(response.permissions);
          setLatestRelease(response.release_notification);
        });
    }
  }, [user?.user?.id]);

  return (
    <>
      <RouterProvider router={router} />
      <Notifications />
      {clientPreviewId && <ClientCIConfirm />}
    </>
  );
};

export default App;
