import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';

import Button, { ButtonVariants } from '../../../../UIKit/Button/Button';
import EmptyList from '../../../../EmptyList/EmptyList';
import Loader from '../../../../Loader/Loader';
import Select from '../../../../UIKit/Select/Select';

import apiClient from '../../../../../apiClient';
import { Deliverable, DeliverableStatus } from '../../NewProjectDeliverables/types';
import { DeliverablesFilterValue } from '../types';
import { ProjectPermissions } from '../../../Login/user.props';
import { activeTask } from '../../ManageDeliverables/manageDeliverables.atom';
import { StatusState } from '../../../../UIKit/StatusLabel/types';
import { ProjectResource } from '../../../Projects/types';

import DownloadSVG from '../../../../../public/media/simple-download.svg';
import SettingsSVG from '../../../../../public/media/deliverable_domains/Settings.svg';

import styles from './SummaryDashboard.module.scss';
import { userClientAtom } from '../../../../../store/auth';
import { SelectedTemplate } from '../../../../DownloadTemplateModal/DownloadTemplateModal';

const sortByName = (a: { caption: string }, b: { caption: string }) => {
  let nameA = a.caption.toUpperCase();
  let nameB = b.caption.toUpperCase();
  return Intl.Collator().compare(nameA, nameB);
};

const PLACEHOLDER_MESSAGE = {
  all: 'Deliverables have not been added',
  [String(DeliverableStatus.INTERNAL)]: 'Internal deliverables have not been added',
  [String(DeliverableStatus.EXTERNAL)]: 'External deliverables have not been added',
};

type AllDeliverablesProps = {
  project: ProjectResource;
  defaultGroupType: 'domain' | 'skill';
  setSelectedTemplates: React.Dispatch<React.SetStateAction<SelectedTemplate[] | null>>;
};

type SlicedDeliverable = Pick<Deliverable, 'caption' | 'id' | 'status' | 'templates'>;

type GroupedDeliverablesResponseType = {
  data: Array<{
    caption: string;
    deliverables: Array<SlicedDeliverable>;
    id: number;
  }>;
};

const filterByDeliverablesType = (deliverables: GroupedDeliverablesResponseType['data'], filter: DeliverablesFilterValue) => (
  filter === 'all' ? deliverables : deliverables
    .filter((deliverable) => deliverable.deliverables.some((d) => d.status.value === Number(filter)))
    .map((group) => ({
      ...group,
      deliverables: group.deliverables.filter((d) => d.status.value === Number(filter)),
    })));

const AllDeliverables = ({ project, setSelectedTemplates, defaultGroupType }: AllDeliverablesProps) => {
  const { t, i18n } = useTranslation();
  const [selectedFilter, setSelectedFilter] = useState<DeliverablesFilterValue>('all');
  const setCurrentTask = useSetAtom(activeTask);
  const navigate = useNavigate();
  const [filteredDeliverables, setFilteredDeliverables] = useState< GroupedDeliverablesResponseType['data'] | undefined>();
  const { clientId } = useParams();
  const [groupType, setGroupType] = useState<'domain' | 'skill'>(defaultGroupType);
  const currentClient = useAtomValue(userClientAtom);

  const { data: deliverables, isLoading } = useSWR(
    [`projects/${project?.id}/grouped-deliverables?group_by=${groupType}`, i18n.language, 'summary', project?.status?.state],
    async ([url]) => (project && project?.status?.state !== StatusState.PENDING ? apiClient
      .get<GroupedDeliverablesResponseType>(url)
      .then(({ response }) => {
        setFilteredDeliverables(filterByDeliverablesType(response.data, selectedFilter));
        return response.data;
      }) : null),
    {
      keepPreviousData: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      onError: (error) => {
        console.error(error);
      },
    },
  );

  useEffect(() => {
    if (deliverables) {
      if (selectedFilter === 'all') {
        setFilteredDeliverables(deliverables);
      } else {
        setFilteredDeliverables(filterByDeliverablesType(deliverables, selectedFilter));
      }
    }
  }, [selectedFilter]);

  const canManageDeliverables = project.permissions.includes(ProjectPermissions.UPDATE);

  return (
    <>
      <div className={styles.drawerSettings}>
        <div className={styles.deliverables__filter}>
          <Select
            options={[
              {
                caption: t('Show All'),
                value: 'all',
              },
              {
                caption: t('Internal'),
                value: String(DeliverableStatus.INTERNAL),
              },
              {
                caption: t('External'),
                value: String(DeliverableStatus.EXTERNAL),
              },
            ]}
            value={selectedFilter as string}
            name='deliverablesfilter'
            labelId='deliverablesfilter'
            setValue={(e) => setSelectedFilter((e as SelectChangeEvent).target.value as DeliverablesFilterValue)}
            type='tile'
            className={styles.deliverables__filter__select}
          />

          <Select
            options={[
              {
                caption: t('Group by domain'),
                value: 'domain',
              },
              {
                caption: t('Group by skill'),
                value: 'skill',
              },
            ]}
            value={groupType as string}
            name='groupType'
            labelId='groupType'
            setValue={(e) => setGroupType((e as SelectChangeEvent).target.value as 'domain' | 'skill')}
            type='tile'
            className={styles.deliverables__filter__select}
          />
        </div>
      </div>
      {isLoading ? <Loader className={styles.loader} /> : filteredDeliverables?.length
        ? (
          <>
            {filteredDeliverables.map((domain) => (
              <div
                key={domain.id}
                className={styles.deliverables__details}
              >
                <h3 className={styles.deliverables__details__title}>{domain.caption}</h3>
                <ul className={styles.deliverables__details__list}>
                  {domain.deliverables.sort(sortByName).map((deliverable) => (
                    <li
                      key={deliverable.id}
                      className={styles.deliverables__details__item}
                    >
                      <p className={styles.deliverable}>{deliverable.caption}</p>
                      <div className={styles.flexBlock}>
                        {currentClient.premium_license
                        && deliverable?.templates.length > 0
                        && deliverable.templates.some(template => template.link === null)
                        && (
                        <button
                          type='button'
                          className={styles.downloadTaskButton}
                          onClick={() => setSelectedTemplates(deliverable.templates)}
                        >
                          <svg>
                            <use
                              xlinkHref={`${DownloadSVG}#simpleDownloadSVG`}
                              href={`${DownloadSVG}#simpleDownloadSVG`}
                            />
                          </svg>
                        </button>
                        )}
                        <p className={classNames(styles.status, {
                          [styles.status_internal]: deliverable.status.value === DeliverableStatus.INTERNAL,
                          [styles.status_external]: deliverable.status.value === DeliverableStatus.EXTERNAL,
                        })}
                        >
                          {deliverable.status.caption}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </>
        )
        : (
          <EmptyList
            title={t(PLACEHOLDER_MESSAGE[selectedFilter])}
            className={styles.deliverables__emptyList}
          />
        )}
      {canManageDeliverables && (
      <footer className={styles.deliverables__footer}>
        <div className={styles.deliverables__footer__content}>
          <Button
            onClick={() => {
              setCurrentTask(null);
              navigate(`/d/client/${clientId}/project/${project.id}/manage-deliverables`);
            }}
            className={styles.deliverables__manageButton}
            variant={ButtonVariants.SECONDARY}
            icon={(
              <svg>
                <use
                  xlinkHref={`${SettingsSVG}#settingsSVG`}
                  href={`${SettingsSVG}#settingsSVG`}
                />
              </svg>
                  )}
            iconSize={{ width: 16, height: 16 }}
          >
            {t('Manage deliverables')}
          </Button>
        </div>
      </footer>
      )}
    </>
  );
};

export default AllDeliverables;
