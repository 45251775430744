import React, { useEffect } from 'react';
import classNames from 'classnames';
import Portal from '../../Portal';
import CloseSVG from '../../../public/media/close.svg';

import styles from './Modal.module.scss';

type ModalProps = {
  children: React.ReactNode,
  rootClassName?: string,
  className?: string,
  modalClassName?: string,
  closeModal?: () => void,
};

const Modal = ({
  children, rootClassName, className, modalClassName, closeModal,
}: ModalProps) => {
  const handleBackdropClick = (event: any) => {
    if (closeModal) {
      const { target, currentTarget } = event;
      const shouldCloseModal = target === currentTarget;

      shouldCloseModal && closeModal?.();
    }
  };

  useEffect(() => {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = `${scrollbarWidth}px`;

    return () => {
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    };
  }, []);

  return (
    <Portal>
      <div
        role='button'
        tabIndex={0}
        className={classNames(styles.modalRoot, rootClassName)}
        onMouseDown={handleBackdropClick}
      >
        <div className={classNames(styles.modal, className, modalClassName)}>
          {!!closeModal && (
          <button
            type='button'
            onClick={closeModal}
            className={styles.closeButton}
          >
            <svg>
              <use
                xlinkHref={`${CloseSVG}#closeSVG`}
                href={`${CloseSVG}#closeSVG`}
              />
            </svg>
          </button>
          )}
          {children}
        </div>
      </div>
    </Portal>
  );
};

export default Modal;
