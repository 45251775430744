import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { useAtom, useAtomValue } from 'jotai/index';
import { Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { TFunction } from 'i18next';
import useSWR from 'swr';

import Popover, { PopoverPlacement } from '../../../../UIKit/Popover/Popover';
import Drawer from '../../../../UIKit/Drawer/Drawer.tsx';
import Button, { ButtonVariants } from '../../../../UIKit/Button/Button.tsx';
import Input from '../../../../UIKit/Input/Input.tsx';
import Tabs from '../../../../UIKit/Tabs/Tabs.tsx';
import Hint from '../../../../UIKit/Hint/Hint.tsx';
import RFPScope from './RFPScope.tsx';
import Loader from '../../../../Loader/Loader.tsx';

import AngleDownSVG from '../../../../../public/media/angle-down.svg';
import DownloadSVG from '../../../../../public/media/download.svg';
import ToolSVG from '../../../../../public/media/tool.svg';
import JiraLogoSVG from '../../../../../public/media/jira-logo.svg';
import JiraOneColorLogoSVG from '../../../../../public/media/jira-one-color-logo.svg';
import ExcelSVG from '../../../../../public/media/excel-icon.svg';
import InfoSVG from '../../../../../public/media/info.svg';
import PdfSVG from '../../../../../public/media/pdf-icon.svg';
import PptxSVG from '../../../../../public/media/pptx-icon.svg';
import ProcessSVG from '../../../../../public/media/process.svg';

import apiClient from '../../../../../apiClient.ts';
import { NewProjectContext } from '../../types.ts';
import { ProjectJiraIntegrationResource, ProjectResource } from '../../../Projects/types.ts';
import { NotificationStatus, notify } from '../../../../../store/notifications.ts';
import { ProjectJiraIntegrationStatus, Tool, ToolIntegrationResource } from '../types';
import { useShortPolling } from '../../../../../useShortPolling.ts';
import { usePermissions } from '../../../../../usePermission.ts';
import { Permissions } from '../../../Login/user.props.ts';

import styles from './SummaryDashboard.module.scss';
import { projectEditAccess } from '../../../../../store/project.ts';

const pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
const xml = 'application/xml';
const pdf = 'application/pdf';

class ValidationError extends Error {
  public errors: Error[];

  constructor(errors: Error[]) {
    super('Multiple validation errors occurred');
    this.errors = errors;
  }
}

const addons = (action: (format: string) => void) => [
  {
    id: 1,
    title: 'Summary report',
    formats: ['PPTX', 'PDF'],
    mainIcon: (
      <svg className={styles.downloadButton__icon}>
        <use
          xlinkHref={`${PdfSVG}#pdfSVG`}
          href={`${PdfSVG}#pdfSVG`}
        />
      </svg>
    ),
    options: [
      {
        id: 'summary-pttx',
        title: 'Microsoft PowerPoint',
        icon: (
          <svg className={styles.downloadButton__icon}>
            <use
              xlinkHref={`${PptxSVG}#pptxSVG`}
              href={`${PptxSVG}#pptxSVG`}
            />
          </svg>
        ),
        action: (project: ProjectResource) => apiClient
          .download(`projects/${project.id}/download-summary?type=summary`, pptx, 'summary.pptx'),
      },
      {
        id: 'summary-pdf',
        title: 'PDF',
        icon: (
          <svg className={styles.downloadButton__icon}>
            <use
              xlinkHref={`${PdfSVG}#pdfSVG`}
              href={`${PdfSVG}#pdfSVG`}
            />
          </svg>
        ),
        action: (project: ProjectResource) => apiClient
          .download(`projects/${project.id}/download-summary?type=summary`, pdf, 'summary.pdf'),
      },
    ],
  },
  {
    id: 2,
    title: 'RfP info',
    formats: ['PPTX', 'PDF'],
    mainIcon: (
      <svg className={styles.downloadButton__icon}>
        <use
          xlinkHref={`${PdfSVG}#pdfSVG`}
          href={`${PdfSVG}#pdfSVG`}
        />
      </svg>
    ),
    options: [
      {
        id: 'RfP-pttx',
        title: 'Microsoft PowerPoint',
        icon: (
          <svg className={styles.downloadButton__icon}>
            <use
              xlinkHref={`${PptxSVG}#pptxSVG`}
              href={`${PptxSVG}#pptxSVG`}
            />
          </svg>
        ),
        action: () => action('PPTX'),
      },
      {
        id: 'RfP-pdf',
        title: 'PDF',
        icon: (
          <svg className={styles.downloadButton__icon}>
            <use
              xlinkHref={`${PdfSVG}#pdfSVG`}
              href={`${PdfSVG}#pdfSVG`}
            />
          </svg>
        ),
        action: () => action('PDF'),
      },
    ],
  },
  {
    id: 3,
    title: 'Project schedule',
    formats: ['XML'],
    info: 'Project schedule export in .xml format. Can be opened in e.g. MS Project, Open Project)',
    mainIcon: (
      <svg className={styles.downloadButton__icon}>
        <use
          xlinkHref={`${ExcelSVG}#excelIcon`}
          href={`${ExcelSVG}#excelIcon`}
        />
      </svg>
    ),
    options: [
      {
        id: 'RfP-mspdi',
        title: 'Schedule',
        icon: (
          <svg className={styles.downloadButton__icon}>
            <use
              xlinkHref={`${ExcelSVG}#excelIcon`}
              href={`${ExcelSVG}#excelIcon`}
            />
          </svg>
        ),
        action: (project: ProjectResource) => apiClient.download(`projects/${project.id}/download-mpp`, xml, 'schedule.mspdi'),
      },
    ],
  },
];

type ClosePopover = {
  [addonId: number]: () => void;
};

const Downloads = () => {
  const { t } = useTranslation();
  const { projectAtom } = useOutletContext<Pick<NewProjectContext, 'projectAtom'>>();
  const project = useAtomValue(projectAtom);
  const [closePopover, setClosePopover] = useState<ClosePopover | null>(null);
  const [isFileDownloading, setIsFileDownloading] = useState(false);

  const onOptionClick = async (addonId: number, downloadCallback: () => Promise<void>) => {
    try {
      closePopover?.[addonId]?.();
      setIsFileDownloading(true);
      await downloadCallback();
    } catch (e) {
      notify();
      console.error(e);
    } finally {
      setIsFileDownloading(false);
    }
  };

  const [isRfpDrawerOpen, setIsRfpDrawerOpen] = useState(false);
  const [rfpFormat, setRfpFormat] = useState<string | null>(null);

  const handleRfpFormat = (format: string) => {
    setRfpFormat(format);
    setIsRfpDrawerOpen(true);
  };

  return (
    <div className={styles.addons__list}>
      {addons(handleRfpFormat).map((addon) => (
        <div
          key={addon.id}
          className={styles.addons__item}
        >
          <div className={styles.addons__item__info}>
            <p className={styles.addons__item__title}>{t(addon.title)}</p>
            <div className={styles.addons__item__extras}>
              <p className={styles.addons__item__formats}>{addon.formats.join(', ')}</p>
              {!!addon.info && (
                <Tooltip
                  arrow
                  placement='bottom-start'
                  enterTouchDelay={0}
                  title={t(addon.info)}
                >
                  <svg className={styles.addons__item__infoSvg}>
                    <use
                      xlinkHref={`${InfoSVG}#infoSVG`}
                      href={`${InfoSVG}#infoSVG`}
                    />
                  </svg>
                </Tooltip>
              )}
            </div>
          </div>
          <Popover
            paperClassName={styles.addons__paper}
            className={styles.downloadButton__wrapper}
            placement={PopoverPlacement.SELECT}
            closeCallback={(callback) => setClosePopover((prev) => ({ ...(prev || {}), [addon.id]: callback }))}
            disabled={isFileDownloading}
            triggerButton={(
              <div className={classNames(styles.downloadButton)}>
                {addon.mainIcon}
                <svg className={styles.downloadButton__arrow}>
                  <use
                    xlinkHref={`${AngleDownSVG}#angleDownSVG`}
                    href={`${AngleDownSVG}#angleDownSVG`}
                  />
                </svg>
              </div>
            )}
          >
            <div className={styles.addons__options}>
              {addon.options.map((item) => (
                <button
                  key={item.id}
                  type='button'
                  className={classNames(styles.downloadButton, styles.downloadButton_wide)}
                  onClick={async () => {
                    onOptionClick(addon.id, async () => item.action(project));
                  }}
                >
                  {item.icon}
                  <p>{t(item.title)}</p>
                </button>
              ))}
            </div>
          </Popover>
        </div>
      ))}
      <Drawer
        isOpen={isRfpDrawerOpen}
        setIsOpen={setIsRfpDrawerOpen}
        title={t('Select scope for RfP info')}
        className={styles.rfp__drawer}
      >
        <RFPScope {...{ project, rfpFormat }} />
      </Drawer>
    </div>
  );
};

const Tools = () => {
  const { t, i18n } = useTranslation();
  const { projectId, clientId } = useParams();

  const { projectAtom } = useOutletContext<Pick<NewProjectContext, 'projectAtom'>>();
  const [project, setProject] = useAtom(projectAtom);

  const [projectJiraIntegrationStatus, setProjectJiraIntegrationStatus] = useState<undefined | ProjectJiraIntegrationStatus>(
    project?.jiraIntegration?.status?.value,
  );
  const [projectJiraURL, setprojectJiraURL] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [projectKey, setProjectKey] = useState(project?.jiraIntegration?.key || '');

  const projectIntegrationIsPending = projectJiraIntegrationStatus === ProjectJiraIntegrationStatus.PENDING;

  const [isJiraDeletingInProgress, setIsJiraDeletingInProgress] = useState(false);

  useEffect(() => {
    setProjectJiraIntegrationStatus(project?.jiraIntegration?.status?.value);
    project?.jiraIntegration?.status?.value === ProjectJiraIntegrationStatus.TASKS_SYNC && setprojectJiraURL(project?.jiraIntegration.url);
    project?.jiraIntegration?.status?.value === ProjectJiraIntegrationStatus.PENDING && setIsSubmitting(true);
  }, [project?.jiraIntegration?.status?.value]);

  useShortPolling(
    async () => {
      const { response } = await apiClient.get<{ data: ProjectJiraIntegrationResource }>(`projects/${projectId}/jira-integration`);
      typeof response.data.status.value === 'number'
        && setProjectJiraIntegrationStatus(response.data.status.value as ProjectJiraIntegrationStatus);
      if (response.data.status.value === ProjectJiraIntegrationStatus.TASKS_SYNC) {
        setprojectJiraURL(response.data.url);
        setIsSubmitting(false);
      }
    },
    {
      isEnabled: projectIntegrationIsPending,
      dependencies: [projectJiraIntegrationStatus],
      interval: 3_000,
    },
  );

  const { data: toolsData, isLoading } = useSWR(
    [`clients/${clientId}/tool-integration`, i18n.language, clientId],
    ([url]) => apiClient.get<{ data: ToolIntegrationResource[] }>(url).then(({ response }) => response.data),
    {
      revalidateOnFocus: false,
      keepPreviousData: false,
      revalidateOnMount: true,
    },
  );

  const isJiraToolSet = !!toolsData?.find((tool) => tool?.type?.value === Tool.JIRA)?.access_token;

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setProjectKey('');
  };

  const createProjectInJira = async () => {
    const { statusCode, response } = await apiClient.post<{ errors?: Record<string, string>; message?: string }>(
      `projects/${projectId}/jira-integration/create-project`,
      { body: JSON.stringify({ project_key: projectKey }) },
    );
    if (statusCode === 201) {
      notify({
        status: NotificationStatus.SUCCESS,
        text: { title: t('Success'), body: response?.message ? t(response.message) : t('Project created in Jira') },
      });
    } else {
      throw new Error(response?.errors ? t(Object.values(response?.errors)?.[0]) : t('Error while creating project in Jira'));
    }
  };

  const createTasksInJira = async () => {
    const { statusCode, response } = await apiClient.post<{
      data: ProjectJiraIntegrationResource;
      errors?: Record<string, string>;
      message?: string;
    }>(`projects/${projectId}/jira-integration/create-tasks`);
    if (statusCode === 200) {
      setProjectJiraIntegrationStatus(response.data.status.value as ProjectJiraIntegrationStatus);
      notify({
        status: NotificationStatus.SUCCESS,
        text: { title: t('Success'), body: response.message ? t(response.message) : t('Task creation in Jira has started') },
      });
    } else {
      throw new Error(response?.errors ? t(Object.values(response?.errors)?.[0]) : t('Error while creating tasks in Jira'));
    }
  };

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      setIsSubmitting(true);
      e.preventDefault();
      if (project?.jiraIntegration?.status?.value !== ProjectJiraIntegrationStatus.CREATED) {
        await createProjectInJira();
      }
      await createTasksInJira();
      closeDrawer();
    } catch (error) {
      notify({ text: error.message ? { body: error.message } : {} });
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onJiraIntegrationClick = async (currentProject: ProjectResource) => {
    // if project has no jira integration or project is created but tasks are not synced
    if (!currentProject.jiraIntegration || currentProject.jiraIntegration?.status?.value === ProjectJiraIntegrationStatus.CREATED) {
      setIsDrawerOpen(true);
    }
    // if project is created and tasks are failed to sync
    if (currentProject.jiraIntegration?.status?.value === ProjectJiraIntegrationStatus.FAILED_SYNC) {
      try {
        setIsSubmitting(true);
        await createTasksInJira();
      } catch (error) {
        console.error(error);
        notify({ text: error.message ? { body: error.message } : {} });
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const onJiraDisconnectClick = async () => {
    let errors: Error[] = [];
    try {
      setIsJiraDeletingInProgress(true);
      const { statusCode, response } = await apiClient
        .delete<{ message?: string, errorMessages?: string[] }>( // errorMessages are error from Jira
        `projects/${projectId}/jira-integration/delete-project`,
      );
      if (statusCode === 204) {
        setProjectJiraIntegrationStatus(undefined);
        setprojectJiraURL('');
        notify({
          status: NotificationStatus.SUCCESS,
          text: { title: t('Success'), body: response?.message ? t(response.message) : t('Project disconnected from Jira') },
        });
        setProject((prev) => ({ ...prev, jiraIntegration: null }));
      } else {
        errors = response.errorMessages
          ? response?.errorMessages.map(er => new Error(er))
          : [new Error(response?.message || t('Error while disconnecting project from Jira'))];
        throw new ValidationError(errors);
      }
    } catch (error) {
      if (error instanceof ValidationError) {
        errors.forEach((e: Error) => {
          notify({ text: e.message ? { body: e.message } : {} });
          console.error(e);
        });
      } else {
        notify({ text: error.message ? { body: error.message } : {} });
        console.error(error);
      }
      console.error(error);
    } finally {
      setIsJiraDeletingInProgress(false);
    }
  };

  const { hasPermission } = usePermissions();
  const hasSetJiraToolPermission = hasPermission(Permissions.INTEGRATION_SAVE);
  const hasJiraManagePermission = hasPermission(Permissions.JIRA_PROJECT_MANAGE);

  const noJiraProjectUserHasNoPermissions = !projectJiraURL && (!hasJiraManagePermission || (!isJiraToolSet && !hasSetJiraToolPermission));
  const noJiraProjectNoToolsUserHasPermission = !projectJiraURL && !isJiraToolSet && hasSetJiraToolPermission;
  const userCanCreateTasks = isJiraToolSet && hasJiraManagePermission;

  const storedProjectEditAccess = useAtomValue(projectEditAccess);
  const isReadOnly = !storedProjectEditAccess?.isEditingAvailableShowOnUI;

  return (
    <>
      <div className={styles.addons__list}>
        {(isLoading || isJiraDeletingInProgress) ? (
          <Loader className={styles.addons__list__loader} />
        ) : noJiraProjectUserHasNoPermissions ? (
          <p className={styles.addons__message}>{t('Contact someone with higher accees level')}</p>
        ) : noJiraProjectNoToolsUserHasPermission ? (
          <Button
            className={styles.addons__tools__button}
            disabled={isReadOnly}
            variant={ButtonVariants.SECONDARY}
            icon={(
              <svg>
                <use
                  xlinkHref={`${JiraLogoSVG}#jiraLogoSVG`}
                  href={`${JiraLogoSVG}#jiraLogoSVG`}
                />
              </svg>
            )}
            iconSize={{ width: 24, height: 24 }}
            link={`/m/client/${clientId}/tool_integration`}
          >
            {t('Set up Jira')}
          </Button>
        ) : projectJiraURL ? (
          <>
            <Button
              className={styles.addons__tools__button}
              variant={ButtonVariants.SECONDARY}
              icon={(
                <svg>
                  <use
                    xlinkHref={`${JiraLogoSVG}#jiraLogoSVG`}
                    href={`${JiraLogoSVG}#jiraLogoSVG`}
                  />
                </svg>
              )}
              iconSize={{ width: 24, height: 24 }}
              link={projectJiraURL}
              target='_blank'
            >
              {t('Jira project')}
            </Button>
            <Button
              variant={ButtonVariants.SECONDARY}
              className={classNames(styles.addons__tools__button, styles.addons__tools__button_disconnect)}
              icon={(
                <svg>
                  <use
                    xlinkHref={`${JiraOneColorLogoSVG}#jiraOneColorLogoSVG`}
                    href={`${JiraOneColorLogoSVG}#jiraOneColorLogoSVG`}
                  />
                </svg>
              )}
              iconSize={{ width: 24, height: 24 }}
              onClick={onJiraDisconnectClick}
            >
              {t('Disconnect project from Jira')}
            </Button>
          </>
        ) : userCanCreateTasks ? (
          <>
            <Button
              className={styles.addons__tools__button}
              onClick={() => onJiraIntegrationClick(project)}
              variant={ButtonVariants.SECONDARY}
              disabled={isSubmitting || projectIntegrationIsPending || isReadOnly}
              icon={
              projectJiraIntegrationStatus === ProjectJiraIntegrationStatus.FAILED_SYNC ? (
                <svg>
                  <use
                    xlinkHref={`${ProcessSVG}#processSVG`}
                    href={`${ProcessSVG}#processSVG`}
                  />
                </svg>
              ) : (
                <svg>
                  <use
                    xlinkHref={`${JiraLogoSVG}#jiraLogoSVG`}
                    href={`${JiraLogoSVG}#jiraLogoSVG`}
                  />
                </svg>
              )
            }
              iconSize={{ width: 24, height: 24 }}
            >
              {projectIntegrationIsPending
                ? t('Syncing tasks with Jira...')
                : projectJiraIntegrationStatus === ProjectJiraIntegrationStatus.FAILED_SYNC
                  ? t('Retry syncing tasks with Jira')
                  : t('Create tasks in Jira')}
            </Button>
            {projectJiraIntegrationStatus === ProjectJiraIntegrationStatus.FAILED_SYNC && (
              <Button
                variant={ButtonVariants.SECONDARY}
                className={classNames(styles.addons__tools__button, styles.addons__tools__button_disconnect)}
                icon={(
                  <svg>
                    <use
                      xlinkHref={`${JiraOneColorLogoSVG}#jiraOneColorLogoSVG`}
                      href={`${JiraOneColorLogoSVG}#jiraOneColorLogoSVG`}
                    />
                  </svg>
                )}
                iconSize={{ width: 24, height: 24 }}
                onClick={onJiraDisconnectClick}
              >
                {t('Disconnect project from Jira')}
              </Button>
            )}
          </>
        ) : null}
      </div>
      <Drawer
        isOpen={isDrawerOpen}
        setIsOpen={closeDrawer}
        title={t('Create tasks in Jira')}
      >
        <form
          onSubmit={submitHandler}
          className={styles.addons__tools__form}
        >
          <div>
            <Input
              id='projectKey'
              label='Jira Key name'
              value={projectKey}
              setValue={(e) => setProjectKey(e.target.value)}
              placeholder='e.g. XYZ'
              disabled={!!project?.jiraIntegration?.key}
            />
            <Hint
              title={t('Bare in mind!')}
              // eslint-disable-next-line max-len
              description={t('The project key must be a string that is required to have a length of up to 5 characters, consist only of uppercase letters followed optionally by numbers. This key should start with at least one letter.')}
              className={styles.addons__tools__hint}
            />
          </div>
          <Button
            type='submit'
            disabled={isSubmitting || isReadOnly}
            loading={isSubmitting}
          >
            {t('Create')}
          </Button>
        </form>
      </Drawer>
    </>
  );
};

const tabs = (t: TFunction<'translation', undefined>) => [
  {
    id: 0,
    slug: 'downloads',
    button: {
      title: (
        <div className={styles.tab}>
          <svg className={styles.tab__icon}>
            <use
              xlinkHref={`${DownloadSVG}#downloadSVG`}
              href={`${DownloadSVG}#downloadSVG`}
            />
          </svg>
          <p>{t('Downloads')}</p>
        </div>
      ),
    },
    content: Downloads,
  },
  {
    id: 1,
    slug: 'tools',
    button: {
      title: (
        <div className={styles.tab}>
          <svg className={styles.tab__icon}>
            <use
              xlinkHref={`${ToolSVG}#toolSVG`}
              href={`${ToolSVG}#toolSVG`}
            />
          </svg>
          <p>{t('Tools integration')}</p>
        </div>
      ),
    },
    content: Tools,
  },
];

const SummaryAddons = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<number>(tabs(t)[0].id);
  const [searchParams, setSearchParams] = useSearchParams();
  const tabsList = tabs(t);

  const changeTab = (tab: number) => {
    setActiveTab(tab);
    const tabData = tabsList.find((tb) => tb.id === tab);
    // remove tab query param, if tab is the first one
    const params = new URLSearchParams(searchParams.toString());
    const isFirstTabActive = tabsList[0].id === tabData?.id;
    isFirstTabActive ? params.delete('tab') : params.set('tab', tabData?.slug || '');
    setSearchParams(params);
  };

  useEffect(() => {
    const currentTab = searchParams.get('tab');
    if (currentTab) {
    // remove tab query param, if slug is not in tabs list
      const currentSlugTabId = tabsList.find((tab) => tab.slug === currentTab)?.id;
      if (!currentSlugTabId) {
        const params = new URLSearchParams(searchParams.toString());
        params.delete('tab');
        setSearchParams(params);
      }
      setActiveTab(currentSlugTabId || tabsList[0].id);
    }
  }, [searchParams]);

  return (
    <section className={classNames(styles.section, styles.addons)}>
      <Tabs
        value={activeTab}
        setValue={changeTab}
        tabs={tabs(t).map((tab) => ({
          ...tab,
          content: typeof tab.content === 'function' ? tab.content() : tab.content,
        }))}
      />
    </section>
  );
};

export default SummaryAddons;
