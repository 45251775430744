import {
  Outlet, useLoaderData, useNavigate, useParams,
} from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useAtom, useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { useMediaQuery } from '@mui/material';
import { TFunction } from 'i18next';
import styles from './QualityGate.module.scss';
import Button, { ButtonIconPosition, ButtonVariants } from '../../UIKit/Button/Button.tsx';
import AngleDownSVG from '../../../public/media/angle-down.svg';
import PlusSVG from '../../../public/media/plus.svg';
import {
  ActionButtonType, ActiveGateType, GateAction, GateResource,
} from './types';
import CalendarSVG from '../../../public/media/summary/quality-gates/calendar.svg';
import { DEFAULT_DATE_FORMAT, MOBILE_MEDIA_QUERY } from '../../../constants.ts';
import { userAtom } from '../../../store/auth.ts';
import StatusLabel from '../../UIKit/StatusLabel/StatusLabel.tsx';
import QualityGateOptionsDropdown from './QualityGateOptionsDropdown/QualityGateOptionsDropdown.tsx';
import { PopoverPlacement } from '../../UIKit/Popover/Popover.tsx';
import DeleteQualityGateModal from './DeleteQualityGateModal/DeleteQualityGateModal.tsx';
import apiClient from '../../../apiClient.ts';
import { notify } from '../../../store/notifications.ts';
import Drawer from '../../UIKit/Drawer/Drawer.tsx';
import QualityGateForm from './QualityGateForm/QualityGateForm.tsx';
import { StatusState } from '../../UIKit/StatusLabel/types.ts';
import QualityGateTabs from './QualityGateTabs/QualityGateTabs.tsx';
import { ProjectResource } from '../Projects/types.ts';
import { isVotingEditModeActive, projectEditAccess, projectGateParticipantsAtom } from '../../../store/project.ts';
import QualityGateMobileTabs from './QualityGateMobileTabs/QualityGateMobileTabs.tsx';
import useEditAccessHandler from '../../../utils/useEditAccessHandler.ts';
import ConfirmPresenceModal from '../../ConfirmPresenceModal/ConfirmPresenceModal.tsx';
import { ProjectPermissions } from '../Login/user.props.ts';

const ActionButtons = (
  {
    actionButtons,
    isMobileDevice,
    t,
  }: { actionButtons: ActionButtonType[] | null, isMobileDevice: boolean, t: TFunction<'translation', undefined> },
) => (
  <div className={styles.actionBtns}>
    {actionButtons?.map(button => (
      <Button
        key={button.label}
        onClick={button?.callback}
        variant={ButtonVariants.SECONDARY}
        icon={(
          <svg>
            <use
              xlinkHref={`${button?.icon?.component ?? PlusSVG}#${button?.icon?.id ?? 'plusSVG'}`}
              href={`${button?.icon?.component ?? PlusSVG}#${button?.icon?.id ?? 'plusSVG'}`}
            />
          </svg>
        )}
        iconSize={{ width: 18, height: 18 }}
        className={styles.addButton}
      >
        {!isMobileDevice && (<span className={styles.addButton__label}>{t(button?.label)}</span>)}
      </Button>
    ))}
  </div>
);

export const DueDateWrapper = ({ children }: { children?: JSX.Element | string }) => (
  <span className={styles.header__content__date}>
    <svg>
      <use
        xlinkHref={`${CalendarSVG}#calendarSVG`}
        href={`${CalendarSVG}#calendarSVG`}
      />
    </svg>
    {children}
  </span>
);

type QualityGateHeaderProps = {
  onBackClick: () => void,
  gate: GateResource | {
    caption: string;
    status: {
      caption: string;
      value: 0;
      state: StatusState.INACTIVE;
    };
    due_date: string;
  },
  userData?: Record<string, any> | null,
  children?: React.ReactNode,
};

export const QualityGateHeader = ({
  onBackClick, gate, userData, children,
}: QualityGateHeaderProps) => {
  const isMobileDevice = useMediaQuery(MOBILE_MEDIA_QUERY);

  return (
    <header className={styles.header}>
      <div className={styles.header__content}>
        <Button
          type='button'
          className={styles.backButton}
          variant={ButtonVariants.SECONDARY}
          onClick={onBackClick}
          icon={(
            <svg>
              <use
                xlinkHref={`${AngleDownSVG}#angleDownSVG`}
                href={`${AngleDownSVG}#angleDownSVG`}
              />
            </svg>
              )}
          iconPosition={ButtonIconPosition.CENTER}
          iconSize={{ width: 18, height: 18 }}
        />
        <div>
          <div className={styles.header__content_title}>
            <h5>{gate?.caption}</h5>
            <StatusLabel
              status={{ caption: gate?.status.caption, value: gate?.status.value, state: gate?.status.state }}
            />
            {isMobileDevice && (
            <DueDateWrapper>
              {dayjs(gate.due_date).format(userData?.user.dateFormat ?? DEFAULT_DATE_FORMAT)}
            </DueDateWrapper>
            )}
          </div>
          {!isMobileDevice && (
          <DueDateWrapper>
            {dayjs(gate.due_date).format(userData?.user.dateFormat ?? DEFAULT_DATE_FORMAT)}
          </DueDateWrapper>
          )}
        </div>
      </div>
      {children}
    </header>
  );
};

const QualityGate = () => {
  const { projectId, clientId, gateId } = useParams();
  const initialProject = (useLoaderData() as { project: ProjectResource })?.project;

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const resetGateParticipants = useResetAtom(projectGateParticipantsAtom);
  const disableVotingEditMode = useResetAtom(isVotingEditModeActive);

  const isMobileDevice = useMediaQuery(MOBILE_MEDIA_QUERY);

  const userData = useAtomValue(userAtom);
  const [activeGate, setActiveGate] = useState<ActiveGateType>({ gate: null, actionType: null });

  const [actionButtons, setActionButtons] = useState<ActionButtonType[] | null>(null);

  useEffect(() => () => {
    resetGateParticipants();
    disableVotingEditMode();
  }, []);

  const { data, mutate } = useSWR(
    [`projects/${projectId}/quality-gates/${gateId}?with=deliverables_count`, i18n.language, gateId, projectId],
    ([url]) => apiClient
      .get<{ data: GateResource, permissions: string[] }>(url).then(({ response }) => response),
    {
      revalidateOnFocus: false,
      keepPreviousData: false,
    },
  );

  const permissions = data?.permissions ?? [];
  const gate = data?.data ?? { caption: '', status: { caption: '', value: 0, state: StatusState.INACTIVE }, due_date: '' };

  const deleteQualityGate = async (id: number) => {
    try {
      const { statusCode } = await apiClient.delete(`projects/${projectId}/quality-gates/${id}`);

      if (statusCode === 204) {
        return navigate(`/d/client/${clientId}/project/${projectId}/summary/quality-gates`);
      } else {
        throw new Error();
      }
    } catch (e) {
      console.error(e);
      notify();
    } finally {
      setActiveGate({ gate: null, actionType: null });
    }
  };

  const editQualityGate = async (body: Record<string, any>, id?: number) => {
    if (!gateId) return;

    try {
      const { statusCode, response } = await apiClient.put<{ message?: string }>(`projects/${projectId}/quality-gates/${id}`, {
        body: JSON.stringify(body),
      });

      if (statusCode === 200) {
        mutate();
      } else {
        throw new Error(response.message);
      }
    } catch (e) {
      console.error(e);
      notify(e?.message ? { text: { body: e.message } } : {});
    } finally {
      setActiveGate({ gate: null, actionType: null });
    }
  };

  const [storedProjectEditAccess, setStoredProjectEditAccess] = useAtom(projectEditAccess);
  let isReadOnly: boolean;
  if (projectId) {
    isReadOnly = !storedProjectEditAccess?.isEditingAvailableShowOnUI;
  } else {
    isReadOnly = false;
  }

  const {
    areYouStillHereModal,
    confirmPresenceHandler,
    valueOfTimer,
    numberOfTimer,
    getOutHandler,
  } = useEditAccessHandler(
    clientId,
    projectId,
    storedProjectEditAccess,
    setStoredProjectEditAccess,
    permissions.includes(ProjectPermissions.UPDATE),
  );

  return (
    <>
      <div>
        <QualityGateHeader
          onBackClick={() => navigate(`/d/client/${clientId}/project/${projectId}/summary/quality-gates`)}
          gate={gate}
          userData={userData}
        >
          {permissions.length ? (
            <QualityGateOptionsDropdown
              permissions={permissions}
              onDeleteClick={() => setActiveGate({ gate: gate as GateResource, actionType: GateAction.DELETE })}
              onEditClick={() => setActiveGate({ gate: gate as GateResource, actionType: GateAction.EDIT })}
              placement={PopoverPlacement.LEFT}
              isReadOnly={isReadOnly}
            />
          ) : null}
        </QualityGateHeader>
        <div className={styles.content}>
          <div className={styles.tabs}>
            {isMobileDevice ? (
              <QualityGateMobileTabs>
                <ActionButtons
                  actionButtons={actionButtons}
                  isMobileDevice={isMobileDevice}
                  t={t}
                />
              </QualityGateMobileTabs>
            ) : (
              <QualityGateTabs>
                <ActionButtons
                  actionButtons={actionButtons}
                  isMobileDevice={isMobileDevice}
                  t={t}
                />
              </QualityGateTabs>
            )}
          </div>
          <div className={styles.outlet}>
            <Outlet context={{ setActionButtons }} />
          </div>
        </div>
      </div>
      {activeGate.gate && activeGate.actionType === GateAction.DELETE && (
        <DeleteQualityGateModal
          closeModal={() => setActiveGate({ gate: null, actionType: null })}
          gate={activeGate.gate}
          onDeleteClick={(id) => deleteQualityGate(id)}
        />
      )}
      <Drawer
        isOpen={!!(activeGate.gate && activeGate.actionType === GateAction.EDIT)}
        setIsOpen={() => setActiveGate({ gate: null, actionType: null })}
        title={t('Edit quality gate')}
      >
        <QualityGateForm
          submit={(values) => editQualityGate(values, activeGate.gate?.id)}
          activeGateId={activeGate.gate?.id}
          submitButtonText={t('Save changes')}
          projectTimeline={{ from: initialProject?.begin, to: initialProject?.end }}
        />
      </Drawer>
      {areYouStillHereModal.isVisible && (
        <ConfirmPresenceModal
          {...{
            getOutHandler,
            confirmPresenceHandler,
            isShowGradientProgressBar: areYouStillHereModal.isVisible,
            valueOfTimer,
            numberOfTimer,
            t,
          }}
        />
      )}
    </>
  );
};

export default QualityGate;
