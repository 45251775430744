import React from 'react';
import { Tooltip } from '@mui/material';
import WarningSVG from '../../../public/media/warning.svg';
import styles from './WarningTooltip.module.scss';

const WarningTooltip = ({ children, id }: { children: string, id: string }) => (
  <Tooltip
    title={children}
    classes={{
      popperInteractive: styles.warningTooltip,
      popperArrow: styles.popperArrow,
      popper: styles.popper,
      tooltip: styles.tooltip,
      arrow: styles.arrow,
    }}
    placement='top'
    enterTouchDelay={0}
    id={id}
    arrow
  >
    <div className={styles.warningTooltip__iconWrapper}>
      <svg>
        <use
          xlinkHref={`${WarningSVG}#warningSVG`}
          href={`${WarningSVG}#warningSVG`}
        />
      </svg>
    </div>
  </Tooltip>
);

export default WarningTooltip;
