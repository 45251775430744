import { Deliverable } from '../NewProject/NewProjectDeliverables/types.ts';
import { StatusState } from '../../UIKit/StatusLabel/types.ts';

export enum GateStatus {
  NOT_DUE_YET,
  NOT_VOTED_YET,
  PASSED,
  PASSED_WITH_CONSTRAINTS,
  FAILED,
}

export type GateResource = {
  websocket_url: string;
  caption: string;
  id: number;
  deliverables: Deliverable[];
  due_date: string;
  executed_date?: string;
  status: {
    caption: string;
    value: GateStatus;
    state: StatusState;
  };
  deliverables_count?: number;
};

export enum GateAction {
  EDIT = 'edit',
  DELETE = 'delete',
}

export type ActiveGateType = {
  gate: GateResource | null;
  actionType: GateAction | null;
};

export type LocalizedResource = {
  caption: Record<string, string>;
};

export type ActionButtonType = {
  label: string;
  callback: () => void;
  icon?: {
    component: string;
    id: string;
  };
};

export type QualityGateContextType = {
  setActionButtons: React.Dispatch<React.SetStateAction<ActionButtonType[] | null>>;
};

export enum ParticipantVote {
  NOT_VOTED,
  PASS,
  PASS_WITH_CONSTRAINTS,
  FAIL,
}

export type ParticipantResource = {
  explanation: string | null;
  id: number;
  role: string;
  name: string;
  deputy: string;
  vote: {
    value: ParticipantVote;
    caption: string;
  };
};

export type ParticipantSelfVotingResource = ParticipantResource & {
  explenation: string;
};

export enum ConstraintStatus {
  OPEN,
  IN_PROGRESS,
  DONE,
}

type ConstraintDelivarable = Omit<Deliverable, 'scores' | 'domain' | 'task'> & {
  done: number;
  due_date: string | null;
};

export type ConstraintResource = {
  id: number;
  caption: string;
  impact: string;
  action: string;
  responsibility: string;
  due_date: string;
  status: {
    caption: string;
    value: ConstraintStatus;
  };
  deliverable: ConstraintDelivarable;
};

export type QualityGateHistoryResource = {
  id: number;
  data: string;
  created_at: string;
};
