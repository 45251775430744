import classNames from 'classnames';

import { useAtomValue } from 'jotai';
import React, { useState } from 'react';
import { CircularProgressbarWithChildren as CircleProgressBar } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import AllDeliverables from './AllDeliverables';
import EmptyList from '../../../../EmptyList/EmptyList';
import Loader from '../../../../Loader/Loader';
import Drawer from '../../../../UIKit/Drawer/Drawer';
import DownloadTemplateModal, { SelectedTemplate } from '../../../../DownloadTemplateModal/DownloadTemplateModal';

import { NewProjectContext } from '../../types';
import { SummaryResponseType } from './types';

import styles from './SummaryDashboard.module.scss';

const getStatusTypePercent = (deliverablesData: SummaryResponseType['deliverables'], type: 'internal' | 'external') => {
  const total = deliverablesData.reduce((acc, curr) => acc + curr.internal_count + curr.external_count, 0);
  const typeAmount = deliverablesData.reduce((acc, curr) => acc + curr[`${type}_count`], 0);
  return (Math.round((typeAmount / total) * 100));
};

type SummaryDeliverablesProps = {
  deliverables: SummaryResponseType['deliverables'] | undefined;
  isLoading: boolean;
  isValidating: boolean;
};
const SummaryDeliverables = ({ deliverables, isLoading, isValidating }: SummaryDeliverablesProps) => {
  const { projectAtom } = useOutletContext<Pick<NewProjectContext, 'projectAtom'>>();
  const project = useAtomValue(projectAtom);
  const { t } = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [selectedTemplates, setSelectedTemplates] = useState<SelectedTemplate[] | null>(null);
  const isAnyDeliverable = deliverables?.some((domain) => !!domain.external_count || !!domain.internal_count);

  return (
    <section className={classNames(styles.section, styles.section_withList, styles.deliverables, {
      [styles.section_validating]: isValidating && !isLoading,
    })}
    >
      {isLoading && !deliverables ? (
        <Loader className={styles.loader} />
      ) : (
        <>
          <header className={styles.sectionHeader}>
            <h3 className={styles.sectionTitle}>{t('Deliverables')}</h3>
            <button
              type='button'
              className={classNames('link', styles.detailsButton)}
              onClick={() => {
                setIsDrawerOpen(true);
              }}
            >
              {t('See all')}
            </button>
          </header>
          {isAnyDeliverable && deliverables?.length ? (
            <>
              <div className={styles.summary}>
                <div className={styles.summary__items}>
                  <div className={styles.summary__item}>
                    <p className={styles.summary__item__title}>{t('Internal')}</p>
                    <span className={classNames(styles.summary__item__dot, styles.summary__item__dot_internal)} />
                    <p className={styles.summary__item__percentage}>
                      {getStatusTypePercent(deliverables, 'internal')}
                      %
                    </p>
                  </div>
                  <div className={styles.summary__item}>
                    <p className={styles.summary__item__title}>{t('External')}</p>
                    <span className={classNames(styles.summary__item__dot, styles.summary__item__dot_external)} />
                    <p className={styles.summary__item__percentage}>
                      {getStatusTypePercent(deliverables, 'external')}
                      %
                    </p>
                  </div>
                </div>
                <div className={styles.summary__diagram}>
                  <CircleProgressBar
                    value={getStatusTypePercent(deliverables, 'internal')}
                    strokeWidth={16}
                  />
                </div>
              </div>
              <div className={classNames(styles.deliverables__list)}>
                <header
                  className={classNames(styles.list__row, styles.list__header, styles.deliverables__list__row, styles.list__header_nowrap)}
                >
                  <p className={styles.list__row_onLeft}>{t('Domain')}</p>
                  <p className={classNames(styles.list__row_onRight)}>{t('Number of deliverables')}</p>
                </header>
                {deliverables.map((domain) => (!!domain.external_count || !!domain.internal_count) && (
                <div
                  key={domain.id}
                  className={classNames(styles.list__row, styles.deliverables__list__row, styles.deliverables__list__item)}
                >
                  <p>{domain.caption}</p>
                  <p className={classNames(styles.list__row_onRight)}>{domain.external_count + domain.internal_count}</p>
                </div>
                ))}
              </div>
            </>
          ) : (
            <EmptyList title={t('Deliverables have not been added')} />
          )}
        </>
      )}
      <Drawer
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        title={t('Deliverables')}
        className={styles.deliverables__drawer}
        contentClassName={styles.deliverables__drawer__content}
      >
        <AllDeliverables
          project={project}
          setSelectedTemplates={setSelectedTemplates}
          defaultGroupType='domain'
        />
      </Drawer>
      {selectedTemplates && (
        <DownloadTemplateModal
          selectedTemplates={selectedTemplates}
          setSelectedTemplates={setSelectedTemplates}
        />
      )}
    </section>
  );
};

export default SummaryDeliverables;
