import { atom } from 'jotai';
import { atomWithReset, atomWithStorage, createJSONStorage } from 'jotai/utils';
import { ParticipantResource } from '../components/pages/QualityGate/types';
import { PollingSettings, ProjectEditAccess, UserThatCurrentlyEditingInfo } from '../components/pages/Projects/types';

type ActionType =
  | {
    type: 'SET_PROJECT_EDITING';
    payload: { projectId: number, requestedAt: string };
  }
  | {
    type: 'SET_EDITING_UNAVAILABLE' | 'SHOW_MODAL_ON_PROJECTS';
    payload: { user: UserThatCurrentlyEditingInfo; projectId: number };
  }
  | {
    type: 'ENTER_VIEW_MODE' | 'SET_EDITING_AVAILABLE';
    payload: { projectId: number }
  }
  | {
    type: 'VIEW_AND_WAIT' | 'GET_OUT';
  };

const projectEditAccessInitialState: ProjectEditAccess = {
  isViewMode: false,
  isEditingAvailable: false,
  isEditingAvailableShowOnUI: false,
  isEditingUnavailableModalVisible: false,
  userThatCurrentlyEditing: {
    userId: null,
    name: null,
    email: null,
  },
  requestedAt: null,
  projectId: null,
};

const editAccessGererator = (action: ActionType): ProjectEditAccess => {
  switch (action.type) {
    case 'SET_PROJECT_EDITING':
      return {
        isViewMode: false,
        isEditingAvailable: true,
        isEditingAvailableShowOnUI: true,
        isEditingUnavailableModalVisible: false,
        userThatCurrentlyEditing: {
          userId: null,
          name: null,
          email: null,
        },
        requestedAt: action.payload.requestedAt,
        projectId: Number(action.payload.projectId),
      };
    case 'SET_EDITING_UNAVAILABLE':
      return {
        isViewMode: false,
        isEditingAvailable: false,
        isEditingAvailableShowOnUI: false,
        isEditingUnavailableModalVisible: false,
        userThatCurrentlyEditing: {
          userId: action.payload?.user.user_id,
          name: action.payload?.user.full_name,
          email: action.payload?.user.email,
        },
        requestedAt: action.payload?.user?.requested_at,
        projectId: Number(action.payload?.projectId),
      };
    case 'SET_EDITING_AVAILABLE':
      return {
        isViewMode: false,
        isEditingAvailable: true,
        isEditingAvailableShowOnUI: false,
        isEditingUnavailableModalVisible: false,
        userThatCurrentlyEditing: {
          userId: null,
          name: null,
          email: null,
        },
        requestedAt: null,
        projectId: Number(action.payload?.projectId),
      };
    case 'ENTER_VIEW_MODE':
      return {
        isViewMode: true,
        isEditingAvailable: false,
        isEditingAvailableShowOnUI: false,
        isEditingUnavailableModalVisible: false,
        userThatCurrentlyEditing: {
          userId: null,
          name: null,
          email: null,
        },
        requestedAt: null,
        projectId: action.payload?.projectId ? Number(action.payload?.projectId) : null,
      };
    case 'SHOW_MODAL_ON_PROJECTS': // modal should be present in the component
      return {
        isViewMode: false,
        isEditingAvailable: false,
        isEditingAvailableShowOnUI: false,
        isEditingUnavailableModalVisible: true,
        userThatCurrentlyEditing: {
          userId: action.payload?.user.user_id,
          name: action.payload?.user.full_name,
          email: action.payload?.user.email,
        },
        requestedAt: action.payload?.user?.requested_at,
        projectId: Number(action.payload?.projectId),
      };
    case 'VIEW_AND_WAIT':
    case 'GET_OUT':
    default:
      return projectEditAccessInitialState;
  }
};

enum ProjectStorageKey {
  PROJECT_EDIT_ACCESS = 'edit-access',
}

const projectIdAtom = atom<number | null>(null);
const storage = createJSONStorage<ProjectEditAccess>(() => sessionStorage);
const projectEditAccess = atomWithStorage<ProjectEditAccess>(ProjectStorageKey.PROJECT_EDIT_ACCESS, {
  isViewMode: false,
  isEditingAvailable: false,
  isEditingAvailableShowOnUI: false,
  isEditingUnavailableModalVisible: false,
  userThatCurrentlyEditing: {
    userId: null,
    name: null,
    email: null,
  },
  requestedAt: null,
  projectId: null,
}, storage, { getOnInit: true });
const pollingSettingsAtom = atom<PollingSettings>({ callback: null, isTriggered: false });
const projectGateParticipantsAtom = atomWithReset<Array<ParticipantResource> | null>(null);
const isVotingEditModeActive = atomWithReset<boolean>(false);

export {
  projectIdAtom, projectEditAccess, pollingSettingsAtom, projectGateParticipantsAtom, isVotingEditModeActive, editAccessGererator,
};
