import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { useOutletContext, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { projectEditAccess } from '../../../../store/project.ts';
import styles from './QualityGateDetails.module.scss';
import QualityGateDeliverable from './QualityGateDeliverable/QualityGateDeliverable.tsx';
import apiClient from '../../../../apiClient.ts';
import { GateResource, QualityGateContextType } from '../types.ts';
import Loader from '../../../Loader/Loader.tsx';
import Drawer from '../../../UIKit/Drawer/Drawer.tsx';
import AddDeliverableForm, { DeliverableType } from './DeliverableForm/AddDeliverableForm.tsx';
import { notify } from '../../../../store/notifications.ts';
import { ProjectPermissions } from '../../Login/user.props.ts';
import EmptyPage from '../../../../public/media/summary/quality-gates/empty-illustration.svg';

const QualityGateDetails = () => {
  const { t, i18n } = useTranslation();
  const { projectId, gateId } = useParams();
  const { setActionButtons } = useOutletContext<QualityGateContextType>();

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const { data, isLoading, mutate } = useSWR(
    [
      `projects/${projectId}/quality-gates/${gateId}?with=deliverables.task,deliverables.links,deliverables.templates`,
      i18n.language,
      gateId,
    ],
    ([url]) => apiClient
      .get<{ data: GateResource, permissions: string[] }>(url).then(({ response }) => response),
    {
      revalidateOnFocus: false,
      keepPreviousData: false,
    },
  );

  const addNewDeliverable = async (values: Record<string, any>) => {
    try {
      if (+values.type === DeliverableType.NEW) {
        const { statusCode } = await apiClient.post(`projects/${projectId}/deliverables`, {
          body: JSON.stringify({
            caption: {
              en: values.titleEn,
              de: values.titleDe,
            },
            due_date: dayjs(values.dueDate).format('YYYY-MM-DD'),
            quality_gate_id: gateId,
          }),
        });

        if (statusCode !== 201) throw new Error();
        await mutate();
      } else {
        const { statusCode } = await apiClient.put(`projects/${projectId}/deliverables/${values.deliverable}`, {
          body: JSON.stringify({ quality_gate: gateId }),
        });

        if (statusCode !== 200) throw new Error();
        await mutate();
      }
    } catch (e) {
      console.error(e);
      notify();
    } finally {
      setIsDrawerVisible(false);
    }
  };

  const permissions = data?.permissions || [];
  const gate = data?.data ?? { deliverables: [] };

  const storedProjectEditAccess = useAtomValue(projectEditAccess);
  let isReadOnly: boolean;
  if (projectId) {
    isReadOnly = !storedProjectEditAccess?.isEditingAvailableShowOnUI;
  } else {
    isReadOnly = false;
  }

  useEffect(() => {
    if (!permissions.includes(ProjectPermissions.UPDATE) || isReadOnly) return;

    setActionButtons([{ label: 'Add deliverable', callback: () => setIsDrawerVisible(true) }]);
    return () => {
      setActionButtons(null);
    };
  }, [permissions, isReadOnly]);

  return (
    <>
      <div className={styles.deliverables}>
        {isLoading ? (
          <div className={styles.loader}>
            <Loader size={44} />
          </div>
        ) : gate?.deliverables?.length ? (
          gate.deliverables.map(deliverable => (
            <QualityGateDeliverable
              key={deliverable.id}
              deliverable={deliverable}
              permissions={permissions}
              isReadOnly={isReadOnly}
              mutate={mutate}
            />
          ))
        ) : (
          <div className={styles.empty}>
            <svg>
              <use
                xlinkHref={`${EmptyPage}#emptyIllustration`}
                href={`${EmptyPage}#emptyIllustration`}
              />
            </svg>
            <p>{t('Q-Gate Deliverables are empty')}</p>
          </div>
        )}
      </div>
      <Drawer
        className={styles.addDeliverableDrawer}
        isOpen={isDrawerVisible}
        setIsOpen={() => setIsDrawerVisible(false)}
        title={t('Add deliverable')}
      >
        <AddDeliverableForm submit={addNewDeliverable} />
      </Drawer>
    </>
  );
};

export default QualityGateDetails;
