import React from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import SummaryDeliverables from './SummaryDeliverables.tsx';
import SummaryAddons from './SummaryAddons.tsx';
import styles from './SummaryDashboard.module.scss';
import SummaryParameters from './SummaryParameters.tsx';
import SummaryScore from './SummaryScore.tsx';
import SummarySkills from './SummarySkills.tsx';
import SummaryStakeholders from './SummaryStakeholders.tsx';
import apiClient from '../../../../../apiClient.ts';
import { SummaryResponseType } from './types.ts';
import SummaryRisks from './SummaryRisks.tsx';

const SummaryDashboard = () => {
  const { projectId } = useParams();
  const { data, isLoading, isValidating } = useSWR([`projects/${projectId}/summary`, 'summary'], ([url]) => apiClient
    .get<SummaryResponseType>(url).then(({ response }) => response));

  return (
    <div className={styles.content}>
      <SummaryParameters />
      <SummaryAddons />
      <SummaryScore />
      <SummaryRisks />
      <SummaryDeliverables
        deliverables={data?.deliverables}
        {...{ isLoading, isValidating }}
      />
      <SummarySkills />
      <SummaryStakeholders />
    </div>
  );
};

export default SummaryDashboard;
