import React, { useEffect, useState } from 'react';
import useSWR, { mutate as globalMutate } from 'swr';
import useSWRImmutable from 'swr/immutable';
import classNames from 'classnames';
import { useOutletContext, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAtom } from 'jotai';
import Button, { ButtonVariants } from '../../../UIKit/Button/Button';
import Drawer from '../../../UIKit/Drawer/Drawer';
import EntityBaseModal from '../../../EntityBaseModal/EntityBaseModal';
import Loader from '../../../Loader/Loader';
import ParticipantForm from './ParticipantForm';
import VotingList from './VotingList';
import VotingResults from './VotingResults';
import Popover, { PopoverPlacement } from '../../../UIKit/Popover/Popover';

import apiClient from '../../../../apiClient';
import { EnumToStringValues } from '../../NewProject/NewProjectSummary/types.ts';
import {
  ConstraintResource,
  GateResource,
  GateStatus,
  ParticipantResource,
  ParticipantVote,
  QualityGateContextType,
} from '../types';
import { NotificationStatus, notify } from '../../../../store/notifications';
import { ProjectPermissions } from '../../Login/user.props';
import {
  editAccessGererator, isVotingEditModeActive, projectEditAccess, projectGateParticipantsAtom,
} from '../../../../store/project';
import { useDebounce } from '../../../../useDebounce.ts';

import EmptyVotingSVG from '../../../../public/media/empty-voting.svg';
import AngleDownSVG from '../../../../public/media/angle-down.svg';
import PdfSVG from '../../../../public/media/pdf-icon.svg';
import PptxSVG from '../../../../public/media/pptx-icon.svg';
import QrCodeSVG from '../../../../public/media/qr-code.svg';

import styles from './Voting.module.scss';
import { UserThatCurrentlyEditingInfo } from '../../Projects/types.ts';
import SelfVotingQR from './SelfVotingQR.tsx';

const CHART_SETTINGS = [
  {
    id: ParticipantVote.PASS_WITH_CONSTRAINTS,
    label: 'Pass with constraints',
    value: 0,
    color: '#FFBB18',
  },
  {
    id: ParticipantVote.PASS,
    label: 'Pass',
    value: 0,
    color: '#60C66A',
  },
  {
    id: ParticipantVote.NOT_VOTED,
    label: 'Not voted',
    value: 0,
    color: '#F4F5F5',
  },
  {
    id: ParticipantVote.FAIL,
    label: 'Fail',
    value: 0,
    color: '#F99B9B',
  },
];

const updateParticipant = async (
  projectId: string,
  gateId: string,
  participant: ParticipantResource,
  body: Record<string, string | number>,
) => {
  try {
    const response = await apiClient.put<{ data: ParticipantResource, message?: string }>(
      `projects/${projectId}/quality-gates/${gateId}/participants/${participant.id}`,
      {
        body: JSON.stringify(body),
      },
    );
    if (response.statusCode === 200) {
      return response;
    } else {
      throw new Error(response.response?.message);
    }
  } catch (error) {
    console.error(error);
    notify((error?.message ? { text: { body: error?.message } } : {}));
  }
};

const createParticipant = async (projectId: string, gateId: string, body: Record<string, string>) => {
  try {
    const response = await apiClient.post<{ data: ParticipantResource }>(`projects/${projectId}/quality-gates/${gateId}/participants`, {
      body: JSON.stringify(body),
    });
    return response;
  } catch (error) {
    console.error(error);
    notify();
  }
};

const deleteParticipant = async (projectId: string, gateId: string, participantId: number) => {
  try {
    const response = await apiClient.delete<{ data: ParticipantResource }>(
      `projects/${projectId}/quality-gates/${gateId}/participants/${participantId}`,
    );
    return response;
  } catch (error) {
    console.error(error);
    notify();
  }
};

const executeQualityGate = async (projectId: string, gateId: string) => {
  try {
    const response = await apiClient.post<{ data: ParticipantResource }>(`projects/${projectId}/quality-gates/${gateId}/execute`);
    return response;
  } catch (error) {
    console.error(error);
    notify();
  }
};

const updateVotes = async (
  projectId: string,
  gateId: string,
  votes: Array<{ id: number, vote: number }>,
) => {
  const response = await apiClient
    .put<{ data: ParticipantResource, message?: string }>(`projects/${projectId}/quality-gates/${gateId}/update-participants-vote-bulk`, {
    body: JSON.stringify({ participants: votes }),
  });
  if (response?.statusCode === 204) {
    return response;
  } else {
    throw new Error(response?.response?.message);
  }
};

const chartAdapter = (participants: ParticipantResource[]) => participants?.reduce(
  (acc, curr) => acc?.map((chart) => (chart.id === curr.vote.value ? { ...chart, value: chart.value + 1 } : chart)),
  CHART_SETTINGS,
);

const pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
const pdf = 'application/pdf';

const DOWNLOAD_FILE_OPTIONS = [
  {
    id: 'summary-pttx',
    title: 'Microsoft PowerPoint',
    icon: (
      <svg className={styles.downloadButton__icon}>
        <use
          xlinkHref={`${PptxSVG}#pptxSVG`}
          href={`${PptxSVG}#pptxSVG`}
        />
      </svg>
    ),
    action: (projectId?: string, gateId?: string) => apiClient
      .download(`projects/${projectId}/quality-gates/${gateId}/download-report`, pptx, 'summary.pptx'),
  },
  {
    id: 'summary-pdf',
    title: 'PDF',
    icon: (
      <svg className={styles.downloadButton__icon}>
        <use
          xlinkHref={`${PdfSVG}#pdfSVG`}
          href={`${PdfSVG}#pdfSVG`}
        />
      </svg>
    ),
    action: (projectId?: string, gateId?: string) => apiClient
      .download(`projects/${projectId}/quality-gates/${gateId}/download-report`, pdf, 'summary.pdf'),
  },
];

const Voting = () => {
  const { t, i18n } = useTranslation();
  const { projectId, gateId } = useParams();
  const { setActionButtons } = useOutletContext<QualityGateContextType>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState<ParticipantResource | null>(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [chartData, setChartData] = useState<typeof CHART_SETTINGS | null>(null);

  const [projectGateVotes, setProjectGateVotes] = useAtom(projectGateParticipantsAtom);
  const [isEditModeActive, setIsEditModeActive] = useAtom(isVotingEditModeActive);

  const [wasChangesMade, setWasChangesMade] = useState(false);

  const [closePopover, setClosePopover] = useState<() => void>(() => {});
  const [isFileDownloading, setIsFileDownloading] = useState(false);

  const [isVotingProcessing, setIsVotingProcessing] = useState(false);

  const [isCastVotesModalVisible, setIsCastVotesModalVisible] = useState(false);

  const onOptionClick = async (downloadCallback: () => Promise<void>) => {
    try {
      closePopover();
      setIsFileDownloading(true);
      await downloadCallback();
    } catch (e) {
      notify();
      console.error(e);
    } finally {
      setIsFileDownloading(false);
    }
  };

  const closeCallback = (func: () => void) => {
    setClosePopover(() => func);
  };

  const {
    data: participants,
    isLoading,
    mutate,
  } = useSWRImmutable(
    [`projects/${projectId}/quality-gates/${gateId}/participants`, i18n.language, gateId, projectId, 'participants'],
    ([url]) => apiClient.get<{ data: ParticipantResource[] }>(url).then(({ response }) => response.data),
  );

  const { data: gateData, mutate: mutateGate } = useSWR(
    [`projects/${projectId}/quality-gates/${gateId}?with=deliverables_count`, i18n.language, gateId, projectId],
    ([url]) => apiClient.get<{ data: GateResource; permissions: string[] }>(url).then(({ response }) => response),
    {
      revalidateOnFocus: false,
      keepPreviousData: false,
    },
  );

  const { data: constraints } = useSWR(
    [`projects/${projectId}/quality-gates/${gateId}/constraints`, i18n.language, gateId, projectId],
    ([url]) => apiClient.get<{ data: ConstraintResource[] }>(url).then(({ response }) => response.data),
    {
      keepPreviousData: true,
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
      revalidateOnMount: true,
    },
  );

  const isGateActive = !gateData?.data?.executed_date;

  const [storedProjectEditAccess, setStoredProjectEditAccess] = useAtom(projectEditAccess);
  let isReadOnly: boolean;
  if (projectId) {
    isReadOnly = !storedProjectEditAccess?.isEditingAvailableShowOnUI;
  } else {
    isReadOnly = false;
  }
  const hasExecutePermission = gateData?.permissions.includes(ProjectPermissions.EXECUTE) && !isReadOnly;

  useEffect(() => {
    if (gateData && hasExecutePermission && isGateActive) {
      setActionButtons([
        { label: 'Cast votes', callback: () => setIsCastVotesModalVisible(true), icon: { component: QrCodeSVG, id: 'qrCodeSVG' } },
        { label: 'Add participant', callback: () => setIsDrawerOpen(true) }]);
    } else {
      setActionButtons(null);
    }
    return () => {
      setActionButtons(null);
    };
  }, [gateData, hasExecutePermission, isGateActive, isReadOnly]);

  useEffect(() => {
    if (participants?.length) {
      const updateList = (latestData: ParticipantResource[]) => latestData.map(participant => {
        const existedParticipant = projectGateVotes?.find(prevParticipant => participant.id === prevParticipant.id);
        return {
          ...participant,
          ...(existedParticipant && existedParticipant.vote.value !== ParticipantVote.NOT_VOTED ? { vote: existedParticipant?.vote } : {}),
        };
      });
      setProjectGateVotes(isEditModeActive || isGateActive ? updateList(participants) : participants);
    }
  }, [participants]);

  const getEditAccess = async () => {
    try {
      const projId = Number(projectId);

      const { statusCode, response } = await apiClient
        .get<{
        data: UserThatCurrentlyEditingInfo,
      }>(`projects/${projId}/edit-mode`);

      if (statusCode === 200) {
        setStoredProjectEditAccess({
          isViewMode: false,
          isEditingAvailable: true,
          isEditingAvailableShowOnUI: true,
          isEditingUnavailableModalVisible: false,
          userThatCurrentlyEditing: {
            userId: response?.data?.user_id,
            name: response?.data?.full_name,
            email: response?.data?.email,
          },
          requestedAt: response?.data?.requested_at,
          projectId: projId,
        });
      } else if (statusCode === 409) {
        setStoredProjectEditAccess(editAccessGererator(
          { type: 'SET_EDITING_UNAVAILABLE', payload: { user: response?.data, projectId: projId } },
        ));
      } else {
        throw new Error();
      }
    } catch (e) {
      notify({ text: e.message ? { body: e.message } : {} });
      console.error(e);
    }
  };

  useEffect(() => {
    if (participants?.length) {
      // simple way to check if two objects are different
      setWasChangesMade(JSON.stringify(participants) !== JSON.stringify(projectGateVotes));
    }
  }, [participants, projectGateVotes]);

  const debouncedChanges = useDebounce<boolean>(JSON.stringify(participants) !== JSON.stringify(projectGateVotes), 1500);

  useEffect(() => {
    wasChangesMade && !storedProjectEditAccess?.isViewMode && getEditAccess();
  }, [wasChangesMade, debouncedChanges, storedProjectEditAccess?.isViewMode]);

  useEffect(() => {
    if (projectGateVotes) {
      setChartData(chartAdapter(projectGateVotes));
    }
  }, [projectGateVotes]);

  const onEditParticipantClick = (participant: ParticipantResource) => {
    setSelectedParticipant(participant);
    setIsDrawerOpen(true);
  };

  const onDeleteParticipantClick = (participant: ParticipantResource) => {
    setSelectedParticipant(participant);
    setIsDeleteModalVisible(true);
  };

  const onClosedDrawer = () => {
    setSelectedParticipant(null);
    setIsDrawerOpen(false);
  };

  const onCloseDeleteModal = () => {
    setSelectedParticipant(null);
    setIsDeleteModalVisible(false);
  };

  const onDeleteParticipant = async () => {
    if (projectId && gateId && selectedParticipant) {
      const response = await deleteParticipant(projectId, gateId, selectedParticipant?.id);
      if (response?.statusCode === 204) {
        mutate();
        onCloseDeleteModal();
      }
    }
  };

  const onCreateFormSubmit = async (formValue: Record<string, string>) => {
    if (projectId && gateId) {
      const response = await createParticipant(projectId, gateId, formValue);
      if (response?.statusCode === 201) {
        mutate();
        onClosedDrawer();
      }
    }
  };

  const onEditFormSubmit = async (formValue: Record<string, string>) => {
    if (projectId && gateId && selectedParticipant) {
      const particapantDefaultVote = participants?.find((participant) => participant.id === selectedParticipant.id)?.vote.value || 0;
      const respose = await updateParticipant(projectId, gateId, selectedParticipant, {
        ...formValue,
        vote: particapantDefaultVote,
      });
      if (respose?.statusCode === 200) {
        mutate();
        onClosedDrawer();
      }
    }
  };

  const onVote = async (voter: ParticipantResource, vote: EnumToStringValues<typeof ParticipantVote>) => {
    if (projectId && gateId && projectGateVotes) {
      setProjectGateVotes(projectGateVotes
        ?.map((prevParticipant) => (prevParticipant.id === voter.id
          ? { ...prevParticipant, vote: { ...prevParticipant.vote, value: Number(vote) } }
          : prevParticipant)));
    }
  };

  const onEndVoting = async () => {
    if (projectId && gateId) {
      setIsVotingProcessing(true);
      try {
        if (wasChangesMade) {
          const votes = projectGateVotes?.map((participant) => ({
            id: participant.id,
            vote: participant.vote.value,
          }));
          if (votes) {
            await updateVotes(projectId, gateId, votes);
            const updatedParticipants = await mutate();
            updatedParticipants && setProjectGateVotes(updatedParticipants);
          }
        }
        const response = await executeQualityGate(projectId, gateId);
        const message = (response?.response as unknown as { message?: string })?.message;
        if (response?.statusCode === 200) {
          mutateGate();
          notify({ text: { body: message, title: t('Success') }, status: NotificationStatus.SUCCESS });
          setIsEditModeActive(false);
        } else if (message) {
          throw new Error(message);
        }
      } catch (e) {
        notify({ text: { body: e.message, title: t('An error occurred on voting ending') } });
      } finally {
        setIsVotingProcessing(false);
      }
    }
  };

  const disabledEditMode = () => {
    setIsEditModeActive(false);
    setProjectGateVotes(participants ?? null);
  };

  const votingCanBeFinished = gateData?.data.status.value !== GateStatus.NOT_DUE_YET ? !!constraints?.length
    || projectGateVotes?.every((participant) => [ParticipantVote.NOT_VOTED, ParticipantVote.PASS].includes(participant.vote.value)) : false;

  // web socket for self-voting flow
  const handleNewParticipant = ({ participant }: { participant: ParticipantResource }) => {
    globalMutate(
      (key: any[]) => key.includes('participants'),
      (prevData: ParticipantResource[] | undefined) => [...(prevData ?? []), participant],
      { revalidate: false }, // prevents revalidating the cache
    );
  };

  const handleUpdateCreatedParticipant = ({ participant: updatedParticipant }: { participant: ParticipantResource }) => {
    globalMutate(
      (key: any[]) => key.includes('participants'),
      (prevData: ParticipantResource[] | undefined) => prevData?.map((participant) => (
        participant.id === updatedParticipant.id ? updatedParticipant : participant
      )),
      { revalidate: false }, // prevents revalidating the cache
    );
  };

  useEffect(() => {
    // It is impossible to export Pusher-js types from the library separately.
    // Therefore, we use "any" here so our bundle does not become even larger.
    let socket: any | undefined;
    if (gateData?.data.websocket_url) {
      const establishSocketConnection = async () => {
        const { default: SocketApi } = await import('../../../../socketApiClient.ts');
        const wsClient = new SocketApi(gateData.data.websocket_url);
        wsClient.on('participant-created-event', handleNewParticipant);
        wsClient.on('participant-updated-event', handleUpdateCreatedParticipant);
        return SocketApi;
      };
      socket = establishSocketConnection();
    }
    () => {
      socket?.off?.();
    };
  }, [gateData]);

  // END web socket for self-voting flow

  return (
    <>
      <section className={styles.voting}>
        <div
          className={classNames(styles.voting__body, {
            [styles.voting__body_noContent]: isLoading || !participants?.length,
          })}
        >
          {isLoading ? (
            <Loader className={styles.loader} />
          ) : !isLoading && !participants?.length ? (
            <div className={styles.placeholder}>
              <svg className={styles.placeholder__img}>
                <use
                  xlinkHref={`${EmptyVotingSVG}#emptyVotingSVG`}
                  href={`${EmptyVotingSVG}#emptyVotingSVG`}
                />
              </svg>
              <p className={styles.placeholder__title}>{t('Voting is empty')}</p>
            </div>
          ) : (
            <>
              <VotingList
                {...{
                  onEditParticipantClick,
                  onDeleteParticipantClick,
                  projectGateVotes,
                  isLoading,
                  onVote,
                  isGateActive,
                  hasExecutePermission,
                  isEditModeActive,
                }}
              />
              {chartData && <VotingResults value={chartData} />}
            </>
          )}
        </div>
        {!!participants?.length && (
          <div className={styles.voting__footer}>
            {(hasExecutePermission) && (
              isGateActive || isEditModeActive
                ? (
                  <>
                    <Button
                      variant={votingCanBeFinished ? ButtonVariants.PRIMARY : ButtonVariants.SECONDARY}
                      className={styles.voting__footer__btn}
                      onClick={onEndVoting}
                      disabled={isVotingProcessing}
                    >
                      {t('End voting')}
                    </Button>
                    {isEditModeActive && (
                    <Button
                      variant={ButtonVariants.SECONDARY}
                      className={styles.voting__footer__btn}
                      onClick={disabledEditMode}
                      disabled={isVotingProcessing}
                    >
                      {t('Cancel')}
                    </Button>
                    )}
                  </>
                )
                : (
                  <Button
                    variant={ButtonVariants.SECONDARY}
                    className={styles.voting__footer__btn}
                    onClick={() => setIsEditModeActive(true)}
                  >
                    {t('Edit voting')}
                  </Button>
                )
            )}
            <Popover
              paperClassName={styles.addons__paper}
              className={styles.downloadButton__wrapper}
              placement={PopoverPlacement.TOP}
              closeCallback={closeCallback}
              disabled={isFileDownloading}
              triggerButton={(
                <div className={classNames(styles.downloadButton)}>
                  <p>{t('Download quality gate report')}</p>
                  <svg className={styles.downloadButton__arrow}>
                    <use
                      xlinkHref={`${AngleDownSVG}#angleDownSVG`}
                      href={`${AngleDownSVG}#angleDownSVG`}
                    />
                  </svg>
                </div>
            )}
            >
              <div className={styles.addons__options}>
                {DOWNLOAD_FILE_OPTIONS.map((item) => (
                  <button
                    key={item.id}
                    type='button'
                    className={classNames(styles.downloadButton, styles.downloadButton_wide)}
                    onClick={async () => {
                      onOptionClick(() => item.action(projectId, gateId));
                    }}
                  >
                    {item.icon}
                    <p>{t(item.title)}</p>
                  </button>
                ))}
              </div>
            </Popover>
          </div>
        )}
      </section>
      <Drawer
        isOpen={isDrawerOpen}
        setIsOpen={(isOpen) => !isOpen && onClosedDrawer()}
        title={t('Add participant')}
        className={styles.drawer}
      >
        <ParticipantForm
          participant={selectedParticipant}
          submitButtonText={selectedParticipant ? t('Edit participant') : t('Add participant')}
          submitForm={(values) => (selectedParticipant ? onEditFormSubmit(values) : onCreateFormSubmit(values))}
        />
      </Drawer>
      <Drawer
        isOpen={isCastVotesModalVisible}
        setIsOpen={setIsCastVotesModalVisible}
        title={t('Scan QR-code to vote')}
        subTitle={t('Use a scanner on your mobile device to reach the voting.')}
        className={styles.drawer}
      >
        <SelfVotingQR {...{ projectId, gateId }} />
      </Drawer>
      {isDeleteModalVisible && (
        <EntityBaseModal
          onClose={() => onCloseDeleteModal()}
          onConfirmClick={onDeleteParticipant}
          heading={`${t('Are you sure you want to delete participant')}?`}
          confirmButtonText={t('Yes, delete')}
          isShowDeleteSVG
        />
      )}
    </>
  );
};

export default Voting;
