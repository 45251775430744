import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { checkUserPermission } from './check-permissions.loader';
import { Permissions } from '../components/pages/Login/user.props';
import apiClient from '../apiClient';
import { ProjectBaseResource } from '../components/pages/Projects/types';

const getProject = async (clientId: string, projectId: string) => {
  try {
    const { response: { data } } = await apiClient
      .get<{ data: ProjectBaseResource }>(`clients/${clientId}/projects/${projectId}`);
    return data;
  } catch (e) {
    console.error(e);
  }
};

export const risksPageLoader = async ({ params }: LoaderFunctionArgs<Record<string, string>>) => {
  const hasPermission = await checkUserPermission(Permissions.RISK_CREATE, (params?.clientId ?? params?.id));
  if (!hasPermission) {
    return redirect('/access-denied');
  } else {
    let project = null;

    if (params.clientId && params.projectId) {
      project = await getProject(params.clientId, params.projectId);
    }

    return { project };
  }
};
